/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getPlayersSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getPlayers } from '../App/actions'

const PlayersLoader = () => {
  const dispatch = useDispatch()

  const players = useSelector((state) => state.toJS().global.players)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const id = useSelector((state) => state.toJS().global.agencyid)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)

  useEffect(() => {
    if (players == null && token != null) {
      dispatch(getPlayers(token, id, isAdmin))
    }
    // eslint-disable-next-line
  }, [players, token])

  return <div />
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'PlayersLoader', saga: getPlayersSaga })
const arg = compose(withSaga, withConnect)(PlayersLoader)

export default arg
