import styled from 'styled-components'
import config from '../../config/configurator'
import backgroundImage from '../../imagesMB/fond.jpg'

const configColors = config.colors.bgSideBar

export const Bg = styled.div`
  text-align:center;
  height: 100%;
  width: 100%;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  min-height: 100vh;
`
export const Loader = styled.div`
border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #024179;
  width: 3rem;
  height: 3rem;
  margin-top:1rem;
  font-family:Poppins;
  animation: spin 1s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`
export const VerticalContainer = styled.div`
  height: 100vh;
  margin: auto;
  width: 26.25rem;
  text-align: center;
  align-items: center;
  background-color: ${configColors};
  background-position: bottom 2% left 50%;
  background-size: 70%;
  position: relative;
`

export const LogoImg = styled.img`
  width: ${config.title === 'PP ADMIN' ? '300px' : '205px'};
  margin-top: ${config.title === 'PP ADMIN' ? '7rem' : '4.5rem'};
  cursor: pointer;
  margin-bottom: 0.75rem;
`

export const Title = styled.div`
  font-family:Poppins;
  font-weight:300;
  font-style:normal;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color:white;
  margin-bottom: 265px;
`

export const Input = styled.input`
  display: block;
  border-radius: 8px;
  background-color: #ffffff;
  border: solid 2px #dadada;
  width: 16.87rem;
  height: 2.3rem;
  box-sizing: border-box;
  margin: auto;
  margin-bottom: 0.6rem;
  padding-left: 1rem;
  font-size: 1rem;
  &:focus {
    outline: none;
    border: 3px solid #00c4f3;
  }
`

export const Button = styled.button`
width: 7.5rem;
height: 3rem;
background-color: #61D7F3;
color: black;
font-family: Poppins;
font-size: 0.875rem;
margin-left:14rem;
margin-top:32.5rem;
display: flex;
justify-content: center;
align-items: center;

border-radius: 0.5rem;
box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.14);
border: none;

&:hover {
  cursor: pointer;
}
@media(max-width:1536px){
  margin-top:24rem;
}
@media(max-width:1440px){
  margin-top:18rem;
}
@media(max-width:1366px){
  margin-top:20.5rem;
}
`
export const TextButton = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-size: 0.9rem;
  line-height: 2rem;
  font-weight 600;

`

export const Id = styled.div`
  font-family:Poppins;
  font-style:normal;
  font-weight:400;
  font-size:0.75rem;
  text-align:left;
  color:white;
  margin-bottom: 0.5rem;
  margin-left:1rem;
  outline: unset;
`

export const Mdp = styled.div`
  font-family:Poppins;
  font-style:normal;
  font-weight:400;
  font-size:0.75rem;
  text-align:left;
  color:white;
  margin-bottom: 0.5rem;
  margin-left:1rem;
  margin-top:1.5rem;
`
export const ButtonsContainer = styled.div`
  width:100%;
  height:3rem;
  display:flex;
  justify-content: flex-end;
`

export const LoginButton = styled.button`
  width: 7.5rem;
  height: 3rem;
  background-color: #61D7F3;
  color: black;
  font-family: Poppins;
  font-size: 0.875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.14);
  border: none;

  &:hover {
    cursor: pointer;
  }
`

export const LegalMention = styled.img`
  position: absolute;
  bottom: 2%;
  left: 16%;
`

export const FeedBack = styled.div`
  color: red;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: 2rem;
`
export const BottomLogoContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 20px;
`

export const BottomLogo = styled.img`
  width: 5.625rem;
`

export const LegalMentionText = styled.div`
  font-family: Catamaran;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  color: white;
  margin-top: 18px;
`
export const LoginContainer = styled.div`
  position:absolute;
  top:60%;
  left:17%;
@media(max-width:1536px){
  position:absolute;
  top:60%;
  left:17%;
}
`