import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import axios from 'axios'
import https from 'https'
import parse from 'papaparse'
import './AgGrid.css'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { saveAs } from 'file-saver'
import UiStats from '../../images/UiStats.svg'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import Select from 'react-select'
import {Bg,ButtonCSV,ButtonContainer, Loader,Loadervalue,Title,AgenciesHeader,Agencies,TotalStatsContainer,PlayerTimeContainer,StatsGlobalContainer,StatsBlocName,CircleStats,StatsName,StatsValue, MainLayout,AgencyContainer} from './styledComponents'
import { LineChart, Line, XAxis, YAxis, BarChart, Bar, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

//import { FilterContainer, FilterSubContainer, IconImg, Input, InputContainer } from '../SimuPlayers/styledComponents'
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const instance = axios.create({
  httpsAgent: new https.Agent({ keepAlive: true, keepAliveMsecs: 6000 }),
  timeout: 60000,
})

const Stats = () => {
    const [stats, setStats] = useState([])
    const [totals, setTotals] = useState([])
    const token = useSelector((state) => state.toJS().global.agencytoken)
    const storedAgencies = useSelector((state) => state.toJS().global.agencies || [])
    const [selectedAgency, setSelectedAgency] = useState([])
    const [tgdEvalAllStats, setTgdEvalAllStats] = useState([])
    const [playerTime, setPlayerTime] = useState(0)
    const [sequences, setSequenceIdCounts] = useState([])
    const [studentsCount,setStudentSequenceIdCounts] = useState([])
    //const [filters, setFilters] = useState({agence: '', Evaluations: '', Initials: '', Total: ''})

    function generateMonthsArray(startDate, endDate) {
      const months = [];
      const start = new Date(startDate)
      const end = new Date(endDate)
    
      while (start <= end) {
        const year = start.getFullYear()
        const month = String(start.getMonth() + 1).padStart(2, '0')
        const formattedMonth = `${year}-${month}`
        months.push(formattedMonth)
        start.setMonth(start.getMonth() + 1)
      }
      return months
    }
    const startDate = '2021-12'
    const endDate = '2023-12'
    const months = generateMonthsArray(startDate, endDate)

    useEffect(() => {
      setStats([])
      getAgenciesStats()
      // eslint-disable-next-line
    }, [selectedAgency]) 
    

    const playerTimeVal = (e) => {
      const updatedPlayerTime = parseInt(e.target.value, 0); // Convert the value to an integer
      setPlayerTime(updatedPlayerTime);
      // Call the function to update the statistics with the new playerTime value
      getTgdAllStats(storedAgencies, selectedAgency, updatedPlayerTime);
    };

    const renderPlayerTime = () => {
      return (
          <PlayerTimeContainer>
            <div>Temps passé sur le simulateur par élèves</div>
              <input
                  type="range"
                  min="0"
                  max="10000"
                  value={playerTime}
                  onChange={playerTimeVal}
              />
              <div>{playerTime}ms</div>
          </PlayerTimeContainer>
      )
  }

    const getAgenciesStats = async () => {
      try {
        const agencies = await instance.get(`${serverPathTgdApi}/agencies`, {
          headers: { Authorization: `Bearer ${token}` },
        })
    
        const filteredAgencies = selectedAgency.length
          ? agencies.data.filter((agency) => selectedAgency.includes(agency.agencyId))
          : agencies.data
    
        await getEvalScore(filteredAgencies)
        await getTgdAllStats(filteredAgencies, selectedAgency)
        renderAgencies(filteredAgencies)
      } catch (error) {
        console.log(error)
      }
    }


    const getEvalScore = async (agencies) => {
      for (let i = 0; i < agencies.length; i++) {
        const promises = months.map(async (month) => {
          try {
            const resEvalScore = await instance.get(
              `${serverPathTgdApi}/students/count?filter=${encodeURI(`{"$and":[{"evalScore":{"$ne":null}}, {"agencyId":"${agencies[i].agencyId}"},{"dateCreation":{"$gte":"${month}-01"}}, {"dateCreation":{"$lte":"${month}-31"}}]}`)}`,
              { headers: { Authorization: `Bearer ${token}` } }
            )
            const resNoEvalScore = await instance.get(
              `${serverPathTgdApi}/students/count?filter=${encodeURI(`{"$and":[{"evalScore":null}, {"agencyId":"${agencies[i].agencyId}"},{"dateCreation":{"$gte":"${month}-01"}}, {"dateCreation":{"$lte":"${month}-31"}}]}`)}`,
              { headers: { Authorization: `Bearer ${token}` } }
            )
            return {
              agencyName: agencies[i].agencyName,
              month,
              studentsWithoutEval: resNoEvalScore.data,
              studentsWithEval: resEvalScore.data
            }
          } catch (err) {
            console.log(err.message)
            return { month, studentsWithoutEval: 0, studentsWithEval: 0 }
          }
        })
        const results = await Promise.all(promises)

        let total = {
          total: results.reduce((acc, result) => acc + result.studentsWithEval + result.studentsWithoutEval, 0),
          totalEval: results.reduce((acc, result) => acc + result.studentsWithEval,0),
          totalInit: results.reduce((acc, result) => acc + result.studentsWithoutEval,0)
        }

        setTotals(prev => [...prev, total])
        setStats(prev => [...prev, results])
      }
    }
    
    const getTgdAllStats = async (agencies, selectedAgency) => {
    try {
    const agencyIds = selectedAgency.length > 0 ? selectedAgency : null
    // eval stats // 
    if (!selectedAgency || selectedAgency.length === 0) {
      const EvalTGD0 = await instance.get(
        `${serverPathTgdApi}/students?filter=${encodeURI('{"evalHours":null}')}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const EvalTGD1 = await instance.get(
        `${serverPathTgdApi}/evals?filter=${encodeURI('{"versionNumber":"TGD1"}')}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
        console.log('evalTGD1',EvalTGD1)
      const EvalTGD2 = await instance.get(
        `${serverPathTgdApi}/evals?filter=${encodeURI('{"versionNumber":"TGD2"}')}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      
      const students = await instance.get(
        `${serverPathTgdApi}/students?filter=${encodeURI(` "playTime":{"$gt":${playerTime}}}`)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      // Extract student IDs
      const progressionsTGD1 = await instance.get(
        `${serverPathTgdApi}/progressions?filter=${encodeURI(
          JSON.stringify({
            sequenceId: { $regex: "leg-ecf" }
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const filteredProgressionsTGD1 = progressionsTGD1.data.map(({ studentId, sequenceId, dateCreation }) => ({ studentId, sequenceId,dateCreation }));

      const progressionsTGD2 = await instance.get(
        `${serverPathTgdApi}/progressions?filter=${encodeURI(
          JSON.stringify({
            sequenceId: { $not: { $regex: "leg-ecf" } },
            journeyId: { $not: { $regex: "leg-ecf" } }
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const filteredProgressionsTGD2 = progressionsTGD2.data.map(({ studentId, sequenceId, journeyId, dateCreation }) => ({ studentId, sequenceId, journeyId, dateCreation }));
    // simu stats // IN PROG
          console.log('fp',filteredProgressionsTGD2)
    
    //  count of students for each sequenceId.
    const sequenceIds = Array.from(
      new Set([
        ...filteredProgressionsTGD1.map(item => item.sequenceId),
        ...filteredProgressionsTGD2.map(item => item.sequenceId)
      ])
    )
    const sequenceIdCounts = {};
    for (const sequenceId of sequenceIds) {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
          JSON.stringify({
            sequenceId: { $eq: sequenceId },
            dateCreation: { $gte: startDate, $lte: endDate },
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      sequenceIdCounts[sequenceId] = countResponse.data;
    }
    setSequenceIdCounts(sequenceIdCounts)
    
    // number of sequenceId by student
    const StudentIds = Array.from(
      new Set([
        ...filteredProgressionsTGD1.map(item => item.studentId),
        ...filteredProgressionsTGD2.map(item => item.studentId)
      ])
    )
    const studentSequenceIdCounts = {}
    for (const studentId of StudentIds) {
      const countResponse = await instance.get(
        `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
          JSON.stringify({
            studentId: { $eq: studentId },  
            dateCreation: { $gte: startDate, $lte: endDate },
          })
        )}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      studentSequenceIdCounts[studentId] = countResponse.data;
    }
    setStudentSequenceIdCounts(studentSequenceIdCounts)


    
    const statsForSelectedAgency = months.map((month) => ({
      month,
      TGD0: EvalTGD0.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD1: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD2: EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD3: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length + EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
      simuTGD1: filteredProgressionsTGD1?.filter((item) => item.dateCreation.startsWith(month)).length,
      simuTGD2: filteredProgressionsTGD2?.filter((item) => item.dateCreation.startsWith(month)).length,
    }))
      setTgdEvalAllStats(statsForSelectedAgency)
    } else {
      // using $in operator cause  MongoDB query language expects an array of values.
      const EvalTGD0 = await instance.get(
        `${serverPathTgdApi}/students?filter=${encodeURI(`{"evalHours":null${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
  
      const EvalTGD1 = await instance.get(
        `${serverPathTgdApi}/evals?filter=${encodeURI(`{"versionNumber":"TGD1"${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
      const EvalTGD2 = await instance.get(
        `${serverPathTgdApi}/evals?filter=${encodeURI(`{"versionNumber":"TGD2"${agencyIds ? `,"agencyId":{"$in": ${JSON.stringify(agencyIds)}}}` : ''}`)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      )
    
      const students = await instance.get(
        `${serverPathTgdApi}/students?filter=${encodeURI(`{"agencyId":{"$in": ${JSON.stringify(agencyIds)}}, "playTime":{"$gt":${playerTime}}}`)}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
        const progressionsTGD1 = await instance.get(
          `${serverPathTgdApi}/progressions?filter=${encodeURI(
            JSON.stringify({
              studentId: { $in: students.data.map(student => student.studentId) },
              sequenceId: { $regex: "leg-ecf" },
              ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
            })
          )}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const filteredProgressionsTGD1 = progressionsTGD1.data.map(({ studentId, sequenceId, dateCreation }) => ({ studentId, sequenceId,dateCreation }))

        const progressionsTGD2 = await instance.get(
          `${serverPathTgdApi}/progressions?filter=${encodeURI(
            JSON.stringify({
              studentId: { $in: students.data.map(student => student.studentId) },
              sequenceId: { $not: { $regex: "leg-ecf" } },
              journeyId: { $not: { $regex: "leg-ecf" } },
              ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
            })
          )}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const filteredProgressionsTGD2 = progressionsTGD2.data.map(({ studentId, sequenceId, dateCreation }) => ({ studentId, sequenceId,dateCreation }))
        
        const sequenceIds = Array.from(
          new Set([
            ...filteredProgressionsTGD1.map(item => item.sequenceId),
            ...filteredProgressionsTGD2.map(item => item.sequenceId)
          ])
        )
        const sequenceIdCounts = {};
        for (const sequenceId of sequenceIds) {
          const countResponse = await instance.get(
            `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
              JSON.stringify({
                sequenceId: { $eq: sequenceId },
                dateCreation: { $gte: startDate, $lte: endDate },
                ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
              })
            )}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          sequenceIdCounts[sequenceId] = countResponse.data;
        }
        setSequenceIdCounts(sequenceIdCounts)
        
        // number of sequenceId by student
        const StudentIds = Array.from(
          new Set([
            ...filteredProgressionsTGD1.map(item => item.studentId),
            ...filteredProgressionsTGD2.map(item => item.studentId)
          ])
        )
        const studentSequenceIdCounts = {}
        for (const studentId of StudentIds) {
          const countResponse = await instance.get(
            `${serverPathTgdApi}/progressions/count?filter=${encodeURI(
              JSON.stringify({
                studentId: { $eq: studentId },  
                dateCreation: { $gte: startDate, $lte: endDate },
                ...(agencyIds ? { agencyId: { $in: agencyIds } } : {}),
              })
            )}`,
            { headers: { Authorization: `Bearer ${token}` } }
          )
          studentSequenceIdCounts[studentId] = countResponse.data;
        }
        setStudentSequenceIdCounts(studentSequenceIdCounts)
    

    const statsForSelectedAgency = months.map((month) => {
     return {
      month,
      TGD0: EvalTGD0.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD1: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD2: EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
      TGD3: EvalTGD1.data.filter((item) => item.dateCreation.startsWith(month)).length + EvalTGD2.data.filter((item) => item.dateCreation.startsWith(month)).length,
      simuTGD1: filteredProgressionsTGD1?.filter((item) => item.dateCreation.startsWith(month)).length,
      simuTGD2: filteredProgressionsTGD2?.filter((item) => item.dateCreation.startsWith(month)).length,
     }
    })
      setTgdEvalAllStats(statsForSelectedAgency)
    }
  } catch (error) {
    console.log(error)
  }
}
    const CSV = () => {
      months.forEach(() => {
        months[0] = 'Janvier'
        months[1] = 'Fevrier'
        months[2] = 'Mars'
        months[3] = 'Avril'
        months[4] = 'Mai'
        months[5] = 'Juin'
        months[6] = 'Juillet'
        months[7] = 'Aout'
        months[8] = 'Septembre'
        months[9] = 'Octobre'
        months[10] = 'Novembre'
        months[11] = 'Decembre'
      })
      
      const csvData = [
        ['',months[0],'','','',months[1],'','','',months[2],'','','',months[3],'','','',months[4],'','','',months[5],'','','',months[6],'','','',months[7],'','','',months[8],'','','',months[9],'','','',months[10],'','','',months[11],'',''],
        ['','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total',
         '','Evaluations', 'Initials', 'Total'
        ],
      ]
      stats.forEach((item) => {
        let isFirstRow = true
        let arr = []
        item.forEach((subItem) => {
          const studentEvalsCount = subItem.studentsWithoutEval + subItem.studentsWithEval
          const evalCount = subItem.studentsWithEval
          const studentsWithoutEval = subItem.studentsWithoutEval
          const agencyName = isFirstRow ? subItem.agencyName : ''
          arr.push(agencyName, evalCount, studentsWithoutEval, studentEvalsCount)
          isFirstRow = false
        })
        csvData.push(arr)
      })
    
      const csv = parse.unparse(csvData)
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })
      saveAs(blob, 'stats.csv')
    }
  
    const renderCSV = () => {
      return (
        <ButtonContainer>
          <ButtonCSV onClick={CSV}>Download CSV</ButtonCSV>
        </ButtonContainer>
      )
    }
    const renderTotalStats = () => {
      const totalStats = {
        TGD0: tgdEvalAllStats.reduce((acc, item) => acc + item.TGD0, 0),
        TGD1: tgdEvalAllStats.reduce((acc, item) => acc + item.TGD1, 0),
        TGD2: tgdEvalAllStats.reduce((acc, item) => acc + item.TGD2, 0),
        TGD3: tgdEvalAllStats.reduce((acc, item) => acc + item.TGD1 + item.TGD2, 0),
        simuTGD1: tgdEvalAllStats.reduce((acc, item) => acc + item.simuTGD1, 0),
        simuTGD2: tgdEvalAllStats.reduce((acc, item) => acc + item.simuTGD2, 0),
        simuTGD3: tgdEvalAllStats.reduce((acc, item) => acc + item.simuTGD2 + item.simuTGD1, 0)
      }
      return (
        <div>
        <StatsGlobalContainer>
          <TotalStatsContainer>
          <CircleStats
          borderColor="#82ca9d"
          borderRightColor="#82ca9d"
          borderBottomColor="#82ca9d"
          />
          <StatsValue style={{ color:'#82ca9d'}}>{totalStats.TGD1}</StatsValue>
          <StatsName  style={{ color:'#82ca9d'}} >TGD1</StatsName>
          </TotalStatsContainer>

          <TotalStatsContainer>
          <CircleStats
          borderColor="#ffc658"
          borderRightColor="#ffc658"
          borderBottomColor="#ffc658"
          />
          <StatsValue style={{ color:'#ffc658'}}>{totalStats.TGD2}</StatsValue>
          <StatsName  style={{ color:'#ffc658'}}>TGD2</StatsName>
          </TotalStatsContainer>
          
          <TotalStatsContainer>
          <CircleStats
          borderColor="#ff7300"
          borderRightColor="#ff7300"
          borderBottomColor="#ff7300"
          />
          <StatsValue style={{ color:'#ff7300'}}>{totalStats.TGD3}</StatsValue>
          <StatsName  style={{ color:'#ff7300'}}>TGD1 + TGD2</StatsName>
          </TotalStatsContainer>

          <TotalStatsContainer>
          <CircleStats
          borderColor="#8884d8"
          borderRightColor="#8884d8"
          borderBottomColor="#8884d8"
          />
          <StatsValue style={{ color:'#8884d8'}}>{totalStats.TGD0}</StatsValue>
          <StatsName  style={{ color:'#8884d8'}}>Sans eval</StatsName>
          </TotalStatsContainer>
        </StatsGlobalContainer>
        <StatsGlobalContainer>
          <TotalStatsContainer>
          <CircleStats
          borderColor="#7BD3EA"
          borderRightColor="#7BD3EA"
          borderBottomColor="#7BD3EA"
          />
          <StatsValue style={{ color:'#7BD3EA'}}>{totalStats.simuTGD1}</StatsValue>
          <StatsName  style={{ color:'#7BD3EA'}}>TGD1</StatsName>
          </TotalStatsContainer>
          <TotalStatsContainer>
          <CircleStats
          borderColor="#F6D6D6"
          borderRightColor="#F6D6D6"
          borderBottomColor="#F6D6D6"
          />
          <StatsValue style={{ color:'#F6D6D6'}}>{totalStats.simuTGD2}</StatsValue>
          <StatsName  style={{ color:'#F6D6D6'}}>TGD2</StatsName>
          </TotalStatsContainer>
          <TotalStatsContainer>
          <CircleStats
          borderColor="#756AB6"
          borderRightColor="#756AB6"
          borderBottomColor="#756AB6"
          />
          <StatsValue style={{ color:'#756AB6'}}>{totalStats.simuTGD3}</StatsValue>
          <StatsName  style={{ color:'#756AB6'}}>TGD1 + TGD2</StatsName>
          </TotalStatsContainer>
          </StatsGlobalContainer>
        </div>
      )
    }
    
    const renderStatsBlocType = () =>{
      return(
        <div style={{display:'flex',flexDirection:'column',position:'relative',justifyContent: 'space-evenly'}}>
        <StatsBlocName>Evaluations</StatsBlocName>
        <StatsBlocName>Simulations</StatsBlocName>
        </div>
      )
    }
    const renderAgencies = (agencies) => {
      const options = agencies.map((agency) => ({
        value: agency.agencyId,
        label: agency.agencyName,
      }))
    
      const handleChange = (selectedOptions) => {
        setSelectedAgency(selectedOptions.map(option => option.value))
      }
    
      return (
        <AgencyContainer>
          <AgenciesHeader>Agences</AgenciesHeader>
          <Select
            options={options}
            isMulti
            value={options.filter(option => selectedAgency.includes(option.value))}
            onChange={handleChange}
            styles={{ fontFamily: 'Poppins', marginTop: '1.5rem' }}
          />
          {selectedAgency.length > 0 && (
            <div style={{ marginTop: '1.5rem', fontFamily: 'Poppins' }}>
              {selectedAgency.map((selectedAgency) => (
                <Agencies key={selectedAgency}>
                  {agencies.find(agency => agency.agencyId === selectedAgency)?.agencyName}
                </Agencies>
              ))}
            </div>
          )}
        </AgencyContainer>
      )
    }
    const renderEval = () => {
      const lineColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff7300']
      return (
        <ResponsiveContainer width="58%" height={400} style={{ background:'white',marginTop: '11rem',borderRadius:'15px' }}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',fontWeight:'bold',textAlign:'center'}}>Eval</div>
          <LineChart data={tgdEvalAllStats} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {["TGD0", "TGD1", "TGD2", "TGD3"].map((dataKey, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={dataKey}
                stroke={lineColors[index]}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )
    }

    const renderSimu = () => {
      const lineColors = ['#8884d8', '#82ca9d', '#ffc658']
      return (
        <ResponsiveContainer width="58%" height={400} style={{ background:'white',marginTop: '11rem',borderRadius:'15px' }}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',fontWeight:'bold',textAlign:'center'}}>Simu</div>
          <LineChart data={tgdEvalAllStats} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            {["simuTGD1", "simuTGD2", "simuTGD3"].map((dataKey, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={dataKey}
                stroke={lineColors[index]}
                activeDot={{ r: 8 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      )
    }
    const renderStudentBySequences = () => {
      const lineColors = ['#8884d8'];
      const dataForChart = Object.entries(sequences).map(([sequenceId, count]) => ({
        sequenceId,
        count,
      }))
      return (
        <ResponsiveContainer width="58%" height={400} style={{ background:'white',marginTop: '2rem',borderRadius:'15px' }}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',fontWeight:'bold',textAlign:'center'}}>Total of Student by Sequences</div>
          <BarChart data={dataForChart} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="sequenceId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {lineColors.map((color, index) => (
              <Bar
                key={index}
                dataKey="count"  // Use the correct key based on your data structure
                fill={color}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      );
    };

    const renderSequenceByStudent = () => {
      const lineColors = ['#8884d8'];
      const dataForChart = Object.entries(studentsCount).map(([studentId, count]) => ({
        studentId,
        count,
      }))
      return (
        <ResponsiveContainer width="58%" height={400} style={{ background:'white',marginTop: '2rem',borderRadius:'15px' }}>
          <div style={{fontFamily:'Poppins',fontSize:'1rem',fontWeight:'bold',textAlign:'center'}}>Total of sequences done by student</div>
          <BarChart data={dataForChart} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="studentId" />
            <YAxis />
            <Tooltip />
            <Legend />
            {lineColors.map((color, index) => (
              <Bar
                key={index}
                dataKey="count"  // Use the correct key based on your data structure
                fill={color}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      )
    }

    const GridExample = () => {
      const rowData = stats.map((item) => {
        const rowObject = {
          Agence: item[0].agencyName,
          Total: item.reduce((acc, subItem) => acc + subItem.studentsWithEval + subItem.studentsWithoutEval, 0),
        }
    
        item.forEach((subItem) => {
          const monthKey = subItem.month
          rowObject[`${monthKey}_Evaluations`] = subItem.studentsWithEval
          rowObject[`${monthKey}_Initials`] = subItem.studentsWithoutEval
        })
        return rowObject
      })
      const columnsDefs = [
        { field: 'Agence', headerName: 'Agence', minWidth: 200},
        ...months.map((month) => ({
          field: `${month}_Evaluations`,
          headerName: `${month} Evaluations`,
        })),
        ...months.map((month) => ({
          field: `${month}_Initials`,
          headerName: `${month} Initials`,
        })),
        { field: 'Total', headerName: 'Total', minWidth: 200 },
      ]
    
      return (
        <div
          className="ag-theme-alpine"
          style={{
            height: '50rem',
            width: '70rem',
            marginTop: '5rem',
            marginLeft:'0rem',
          }}
        >
          <AgGridReact rowData={rowData} columnDefs={columnsDefs} />
        </div>
      )
    }
    return (
      <Bg>
        <SideBar />
        <MainLayout>
        <MainTitle text='Statistiques' icon={UiStats}/>
        {/*stats.length < /*storedAgencies.length 1 ? 
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',marginLeft:'10%',fontSize:'2rem', flexDirection:'column'}}>
            <Loader></Loader>
              <Loadervalue><div style={{color:'#024179',paddingRight:'0.5rem'}}>{stats.length}</div> / {storedAgencies.length}</Loadervalue>
          </div> : 
          */
          <>
          <Title>Mes Statistiques</Title>
            <div style={{display:'flex',flexDirection:'row',marginTop:'11rem',marginLeft:'-5.5rem'}}>
            {renderEval()}
            {renderSimu()}
            </div>
            <div style={{display:'flex',flexDirection:'row',marginLeft:'-5.5rem'}}>
            {renderStudentBySequences()}
            {renderSequenceByStudent()}
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
            {renderAgencies(storedAgencies)}
            {renderPlayerTime()}
            </div>
            <div style={{display:'flex',flexDirection:'row'}}>
            {renderStatsBlocType()}
            {renderTotalStats()} 
            </div>
            {renderCSV()}
            {GridExample()}
          </>
        }
        </MainLayout>
      </Bg>
      
    )
  }

const withConnect = connect(null, null)
const arg = compose(withConnect)(Stats)
export default arg
