import styled from 'styled-components'
import config from '../../config/configurator'
import UiContact from '../../images/contact.svg'

export const LeftBar = styled.div`
  width: 15.10%;
  height: 100%;
  color: #2d3243;
  background-color: ${config.colors.bgSideBar};
  position: fixed;
  font-family: Poppins;
  font-weight:500;
  font-size: 1.2rem;
  @media(max-width:1024px)
  {
    width:19%;
  }
`

export const ButtonContainer = styled.div`
  width: 88%;
  padding-left: 12%;
  margin-top: 10%;
  @media(max-width:1536px){
    font-size:1rem;
  }
  @media(max-width:1366px){
    font-size:0.9rem;
  }
  @media(max-width:1280px){
    font-size:0.8rem;
  }
`

export const SideLogo = styled.img`
margin-left: ${config.title === 'PP ADMIN' ? '25%' : '20%'};
margin-top: 3.2rem;
width: ${config.title === 'PP ADMIN' ? '50%' : '60%'};
margin-bottom: 22%;
`

export const Text = styled.div`
  width: 100%;
  height: 1.31rem;
  font-family:Poppins;
  font-weight:500;
  font-style:normal;
  font-size:0.875rem;
  color:white;
  z-index:2;
  text-align: center;
  margin-top: -20%;
  padding-bottom: 40%;
`

export const Contact = styled.div`
  position: absolute;
  background-image: url(${UiContact});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 10%;
  bottom: 0;
  left: 0;
  background-color: black;
`

export const User = styled.div`
  width: 100%;
  font-family: Catamaran;
  font-weight: 300;
  color: white;
  text-align: left;
  font-size: 1.1rem;
  display:flex;
  flex-direction:row;
  line-height: 1rem;
  margin-bottom: 1rem;
`
export const AgencyName = styled.div`
line-height: 1rem;
margin-top:0.1rem;
`
export const UserImg = styled.img`
  margin-right: 2%;
`

export const UserBloc = styled.div`
  position: absolute;
  width: 100%;
  height: 5rem;
`

export const ButtonLogOut = styled.div`
width:54.48%;
display: flex;
justify-content: center;
align-items: center;
height:2.2rem;
border:1px solid white;
border-radius:0.6rem;
color:white;
cursor:pointer;
@media(max-width:1536px){
  width:65.48%;
  line-height:2.2rem;
}
@media(max-width:1366px){
  width:73.48%;
}
`

export const LogoutImg = styled.img`
width: 1.2rem;
`
export const LogoutText = styled.div`
font-family:Poppins;
font-style:normal;
font-weight:600;
font-size: 0.75rem;
margin-left: 8px;
`

export const AdminContainer = styled.div`
display: flex;
justify-content: center;
margin-top: -37%;
height: 10%
`

export const Admin = styled.div`
display: flex;
justify-content: center;
align-items: center;
background-color: #FFF6A4;
height: 1.5rem;
width: 3.375rem;
border-radius: 0.75rem;
font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 0.625rem;
line-height: 15px;
`

export const SpaceBetween = styled.div`
`