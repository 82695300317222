import React from 'react'
import LoadingSpinner from '../../components/LoadingSpinner/index'
import PopUp from '../../components/PopUp'
import UiUpload from '../../images/UiUploadImg.svg'
import { Spacer } from '../../components/StylesEditor/index.js'

export const PopUpUpload = (props) => {
  if (props.showPopUp.status === 'loading') {
    return (
      <PopUp
        text={props.showPopUp.message}
        icon={UiUpload}
      >
        <Spacer height='2rem' />
        <LoadingSpinner />
      </PopUp>
    )
  } else if (props.showPopUp.status === 'waitToClick') {
    return (
      <PopUp
        text={props.showPopUp.message}
        textYES='Retour'
        callbackYes={() => props.resetPopUp()}
        icon={UiUpload}
      />
    )
  } else return null
}

export default PopUpUpload
