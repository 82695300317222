/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { LOAD_JOURNEYS } from '../App/constants'
import { journeysLoaded, journeysLoadingError } from '../App/actions'

import request from '../../utils/request'

import { tokenize } from '../../lib/accessToken'

const serverPath = process.env.REACT_APP_SERVER_API
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

export function * getJourneys ({ token }) {
  let journeys = []
  const requestURL = `${serverPath}/api/journeys`
  if (!isB2VM) {
    try {
      journeys = yield call(request, tokenize(requestURL), { method: 'GET' })
    } catch (err) {
      yield put(journeysLoadingError(err))
    }
    try {
      yield put(journeysLoaded(journeys))
    } catch (err) {
      yield put(journeysLoadingError(err))
    }
  }

}

/**
 * Root saga manages watcher lifecycle
 */
export function * journeysData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(LOAD_JOURNEYS, getJourneys)
}
