import React from 'react'
import { Bg, Container, ContentText, Title, Logo, Name, ContentBox, ButtonAction, ButtonCancel, FirstWord, TextContent } from './styledComponents'
import boyLogo from '../../images/boy.svg'

const ModalConfirm = (props) => {
  const bText = props.confirmText ? props.confirmText : 'Confirmer'
  if (!props.show) {
    return (<div />)
  }

  return (
    <Bg>
      <Container style={{ width: props.text === 'Vous allez vous déconnecter' ? '18%' : '359px', height: props.text === 'Vous allez vous déconnecter' ? '16%' : '225px' }}>
        <ContentText>
          <TextContent>
          {bText === 'Supprimer' ?
          <Title><FirstWord>{props.text.split(' ')[0]}</FirstWord>{props.text.split(',')[1]}</Title>
            : 
          <Title>{props.text}</Title>}
          </TextContent>
          <Name>
            {props.name !== undefined ? <Logo src={boyLogo} /> : null}
            {props.name}
          </Name>
        </ContentText>
        <ContentBox>
          {props.noCancel === undefined
            ? <ButtonCancel onClick={() => props.cancel()}>
              Annuler
            </ButtonCancel>
            : null}
          <ButtonAction style={{
            backgroundColor: bText === 'Supprimer' ? 'red' : '#61D7F3',
            color: bText === 'Supprimer' ? 'white' : 'black',
            width: props.confirmText === 'Se déconnecter' ? '143px' : '33.75%',
            fontWeight: props.confirmText === 'Se déconnecter' ? '600' : 'unset'
            }}
            onClick={() => props.confirm()}>
            {bText}
          </ButtonAction>
        </ContentBox>
      </Container>
    </Bg>
  )
}

export default ModalConfirm
