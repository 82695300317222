import { call, put, takeLatest } from 'redux-saga/effects'
import { SIMU_PLAYER_EDIT, DELETE_SIMU_PLAYER, REFRESH_SIMU_PLAYERS } from '../App/constants'
import {
  deleteSimuPlayerSuccess,
  deleteSimuPlayerError,
  simuPlayerEditSuccess,
  simuPlayerEditError,
  refreshSimuPlayersSuccess,
  refreshSimuPlayersError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'
// const serverPath = `https://ver-client.tgd-test.site`
/**
 * Github repos request/response handler
 */

export function * simuPlayerEdit ({ credentials }) {
  const editPlayersUrl = `${serverPath}/api/playerTGDs/editPlayer?id=${credentials.id}&firstname=${credentials.firstname}&lastname=${credentials.lastname}&email=${credentials.email}${credentials.agencyid ? `&agencyId=${credentials.agencyid}` : ''}${credentials.password ? `&password=${credentials.password}` : ''}&access_token=${credentials.token}`
  const getPlayerUrl = `${serverPath}/api/playerTGDs/${credentials.id}?access_token=${credentials.token}`

  const patchStudentTgdApisUrl = `${serverPathTgdApi}/students/${credentials.id}`
  const getStudentTgdApiUrl = `${serverPathTgdApi}/students/${credentials.id}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${credentials.token}`)
  const body = credentials.password !== '' ?  JSON.stringify({ password: credentials.password, agencyId: credentials.agencyid, trainingType: credentials.trainingType }) : JSON.stringify({ agencyId: credentials.agencyid, trainingType: credentials.trainingType })

  const patchOptions = {
    method: 'PATCH',
    headers,
    body
  }

  const getOptions = {
    method: 'GET',
    headers,
  }

  try {
    if (isB2VM) {
      yield call(request, patchStudentTgdApisUrl, patchOptions)
      const student = yield call(request, getStudentTgdApiUrl, getOptions)
      console.log(student)
      yield put(simuPlayerEditSuccess(student))
    } else {
      yield call(request, editPlayersUrl, { method: 'PATCH' })
      const player = yield call(request, getPlayerUrl, { method: 'GET' })
      yield put(simuPlayerEditSuccess(player))
    }
  } catch (err) {
    yield put(simuPlayerEditError(err.message))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * simuPlayerEditData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SIMU_PLAYER_EDIT, simuPlayerEdit)
}

export function * deleteSimuPlayer ({ token, id, agencyid }) {
  console.log('DELETE PLAYER', token)
  console.log(id)
  // const url = `${serverPath}/getPlayers/${password}`
  const urlPlayerDelete = `${serverPath}/api/playerTGDs/${id}?access_token=${token}`
  const urlTgdApi = `${serverPathTgdApi}/students/${id}`
  // FIXME use api/agencies?filter[include][players]=sequenceProgressions&filter[where][id]=${id}&access_token=${id}
  const urlPlayers = `${serverPath}/api/playerTGDs?filter[where][agencyId]=${agencyid}&access_token=${token}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${token}`)
  const options = {
    method: 'DELETE',
    headers
  }

  try {
    if (isB2VM) {
      // Call our request helper (see 'utils/request')
      yield call(request, urlTgdApi, options)
      yield put(deleteSimuPlayerSuccess())
    } else {
      // Call our request helper (see 'utils/request')
      yield call(request, urlPlayerDelete, { method: 'DELETE' })
      yield call(request, urlPlayers, { method: 'GET' })
      yield put(deleteSimuPlayerSuccess(/* players */))
    }
  } catch (err) {
    yield put(deleteSimuPlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteSimuPlayerSaga () {
  yield takeLatest(DELETE_SIMU_PLAYER, deleteSimuPlayer)
}

export function * refreshSimuPlayers ({ token, id, isAdmin }) {
  const filter = isAdmin ? '' : `filter[where][agencyId]=${id}&`
  const urlPlayers = `${serverPath}/api/playerTGDs?${filter}access_token=${token}`
  //const urlREMC = `${serverPath}/api/playerTGDs/${id}/agencySaveREMC?isAdmin=${isAdmin}&access_token=${token}`

  //let remc
  let players = []

  try {
    players = yield call(request, urlPlayers, { method: 'GET' })
    // remc = yield call(request, urlREMC, { method: 'GET' })

    // players.forEach(pl => {
    //   const tmpProg = remc.filter(pr => { return pr.id === pl.id })
    //   pl.prog = tmpProg[0] ? tmpProg[0].save : {}
    // })

    yield put(refreshSimuPlayersSuccess(players))
  } catch (err) {
    yield put(refreshSimuPlayersError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshSimuPlayersSaga () {
  yield takeLatest(REFRESH_SIMU_PLAYERS, refreshSimuPlayers)
}
