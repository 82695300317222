import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getTokenSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getTokenAgency, hideModal } from '../App/actions'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import AddStudent from '../../images/Add.svg'
import ButtonUploadAws from '../../components/ButtonUploadAws'
import SuccessIcon from '../../images/Icone_agence.svg'
import ModalConfirm from '../../components/ModalConfirm/index'
import UiDanger from '../../images/UiWarn.svg'
import config from '../../config/configurator'
import { EntitySelectorContainer, EntitySelector, UploadContainer, UploadTitle, UploadInput, FormContainer, FormAndLogoContainer, BoxContainer, Name, Input, DeleteInput, FeedBack, Bg, Button, ButtonContainer, MainLayout, Success, ImgSuccess, TextSuccess, Container } from './styledComponents'
// import UiCross from 'images/close.svg'

const AgencyCreate = () => {
  const dispatch = useDispatch()

  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)
  const successStore = useSelector((state) => state.toJS().global.success)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [autorisation, setAutorisation] = useState('')
  const [agencyPassword, setAgencyPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [wrongRepeat, setWrongRepeat] = useState()
  const [errorCode, setErrorCode] = useState(0)
  const [logo, setLogo] = useState('')
  const [show, setShow] = useState(false)
  const [modalText, setModalText] = useState(false)
  const [enable, setEnable] = useState(false)
  const [verif, setVerif] = useState(false)
  const [success, setSuccess] = useState(false)
  const [warn, setWarn] = useState({ mail: false, psw: false, fName: false, error: false })
  const [borderColor, setBorderColor] = useState({ mail: '', psw: '' })
  const [color, setColor] = useState()
  const [backgroundColor, setBackgroundColor] = useState()
  const [entity, setEntity] = useState('TGD')

  useEffect(() => {
    displayModal(successStore)
    if (config.title !== 'ML ADMIN') {
      emailCheck()
      verification()
      passwordCheck()
    } else {
      verificationML()
      passwordCheck()
    }
    setErrorCode(0)
    if(!successStore) {
      if(error) {
        if (error !== undefined && error.response !== undefined) {
          switch (error.response.status) {
            case 401:
              setShow(true); setSuccess(false); setWarn({ error: true }); setModalText('Vous n\'êtes pas identifié, veuillez vous déconnecter puis vous reconnecter'); setErrorCode(error)
              break
            default:
              setShow(true); setSuccess(false); setWarn({ fName: true }); setModalText('Erreur système'); setErrorCode(error.response.status)
              break
            case 409:
              setShow(true); setSuccess(false); setWarn({ error: true }); setModalText(`L'agence existe déjà.`); setErrorCode(error.response.status)
              break
            case 500:
              setShow(true); setSuccess(false); setWarn({ error: true }); setModalText(`L'agence existe déjà.`); setErrorCode(error.response.status)
              break
          }
        } else {
          setShow(true); setSuccess(false); setWarn({ error: true }); setModalText('Impossible d\'accéder au serveur'); setErrorCode(error)
        }
      }
    }
    // eslint-disable-next-line
  }, [error, successStore])

  const displayModal = (show) => {
    if(show){
      setSuccess(true)
      setTimeout(() => {
        dispatch(hideModal())
        setSuccess(false)
      }, 2000);
    }
  }
  const passwordCheck = () => {
    if (repeatPassword && agencyPassword && (agencyPassword !== repeatPassword)) {
      setWrongRepeat(true)
      setBackgroundColor('#EFF0F6')
      setColor('#868383')
      console.log('wrongRepeat',wrongRepeat)
    } else {
      setWrongRepeat(false)
    }
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickHandlerAccount()
    }
  }

  const clickHandlerAccount = () => {
    if (!verif) {
      return null
    } else {
      setWrongRepeat(false); setErrorCode(200); setName(''); setEmail(''); setAgencyPassword(''); setEntity('TGD'); setRepeatPassword('');setAutorisation(''); setLogo('')
      dispatch(getTokenAgency(name, agencyPassword, agencyToken, entity, logo, email, autorisation))
      setTimeout(() => {
        setSuccess(false)
      }, 2000)
    }
  }

  const verification = () => {
    if (name && agencyPassword && email && repeatPassword && !wrongRepeat && !warn.mail && !warn.psw) {
      setBackgroundColor('#00C4F3')
      setColor('black')
      setEnable(true)
      setVerif(true)
    } else {
      setBackgroundColor('#EFF0F6')
      setColor('#868383')
      setEnable(false)
      setVerif(false)
    }
  }

  const verificationML = () => {
    if (name && agencyPassword && repeatPassword && !wrongRepeat && !warn.psw) {
      setBackgroundColor('#00C4F3')
      setColor('black')
      setEnable(true)
      setVerif(true)
      setEmail(name + '@b.com')
    } else {
      setBackgroundColor('#EFF0F6')
      setColor('#868383')
      setEnable(false)
      setVerif(false)
    }
  }

  const renderTitle = () => {
    return <MainTitle text='Créer une agence' icon={AddStudent} />
  }

  const emailCheck = () => {
    if (!email) {
      setBorderColor({ mail: '#B3ABBC' })
      setWarn({ mail: false })
      return true
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null) {
      setBorderColor({ mail: '#FF0000' })
      setWarn({ mail: true })
      return false
    } else {
      setBorderColor({ mail: '#B3ABBC' })
      setWarn({ mail: false })
      return true
    }
  }

  // # # # # # # # # # # # # # UPLOAD MEDIA # # # # # # # # # # #
  const renderMediaUpload = () => {
    return (
      <UploadContainer>
        <UploadTitle>Ajouter un logo</UploadTitle>
        {/* This pop up will show if we change media type while the previous media is still on aws */}
        {/* {showPopUpWarning && (
          <PopUp
            text='Supprimez d abord l ancien media'
            textNO='blah ok'
            callbackNo={() => setState({ showPopUpWarning: false })}
          />
        )} */}
        <ButtonUploadAws
          prevFilename={logo}
          onUpload={(fileName) => { setLogo(fileName) }}
          onDelete={() => { setLogo(null) }} // save in the state
          folder='agencyLogos/'
          width='21.3rem'
          height='12.1rem'
        />
      </UploadContainer>
    )
  }

  const entities = [
    {value: 'TGD', label: 'TGD'},
    {value: 'IO', label: 'ICI OFFICE'}
]

  const DisplayEntitySelector = () => {
      return (
        <EntitySelector
          classNamePrefix='react-select'
          multiple={false}
          isSearchable={false}
          value={entities ? entities.value : null}
          placeholder={<p style={{ color: 'black'}}>{entities[0].label}</p>}
          onChange={(e) => {setEntity(e.value)}}
          options={entities}>
        </EntitySelector>
      )
  }

  const renderContainer = () => {
    return (
      <FormAndLogoContainer style={{ justifyContent: config.layouts.agencyCreate.logo === false ? 'center' : 'unset' }}>
        <FormContainer style={{ marginLeft: config.layouts.agencyCreate.logo === false ? '0' : '17.8rem' }}>
          <BoxContainer>
            <Name>Nom de l'agence</Name>
            <Input
              value={name}
              style={{ borderColor: errorCode === 409 ? 'red' : '#B3ABBC' }}
              // onFocus={() => {showDelete('delAgencyName', true)}}
              // onBlur={() => {setTimeout(() => {showDelete('delAgencyName', false)}, 100)}}
              onKeyUp={() => config.title === 'ML ADMIN' ? verificationML() : verification()}
              onChange={(e) => setName(e.target.value)}
            />
            {/* {delAgencyName && name ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => emptyInput('name')} />) : ''} */}
            {errorCode === 409 ? (<DeleteInput style={{ bottom: '44%', right: '3%', height: '1rem' }} src={UiDanger} />) : ''}
            <FeedBack style={{ opacity: warn.fName ? '1' : '0' }}>
              Tous les champs ne sont pas valides
            </FeedBack>
          </BoxContainer>
          {config.title !== 'ML ADMIN'
            ? <BoxContainer>
              <Name>Email</Name>
              <Input
                value={email}
                style={{ borderColor: borderColor.mail }}
                // onFocus={() => {showDelete('delMail', true)}}
                // onBlur={() => {setTimeout(() => {showDelete('delMail', false)}, 100)}}
                onKeyUp={() => {verification(); emailCheck()}}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              {(warn.mail || errorCode === 409) && email ? (<DeleteInput style={{ bottom: '44%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : ''}
              {/* {delMail && email ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('email'); setState({ borderColorMail: '#B3ABBC', warnMail: false })}} /> ) : ''} */}
              <FeedBack style={{ opacity: warn.mail ? '1' : '0' }}>
                Email non valide
              </FeedBack>
              </BoxContainer>
            : null}
          <BoxContainer>
            <Name>Mot de passe</Name>
            <Input
              value={agencyPassword}
              style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
              // onFocus={() => {showDelete('delPsw', true)}}
              // onBlur={() => {setTimeout(() => {showDelete('delPsw', false)}, 100)}}
              onKeyUp={() => config.title === 'ML ADMIN' ? verificationML() : verification()}
              type='text'
              onChange={(e) => setAgencyPassword(e.target.value)}
            />
            {/* {delPsw && agencyPassword ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('agencyPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
            {wrongRepeat ? (<DeleteInput style={{ bottom: '44%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : null}
          </BoxContainer>
          <BoxContainer>
            <Name>Répéter le mot de passe</Name>
            <Input
              style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
              // onFocus={() => {showDelete('delRep', true)}}
              // onBlur={() => {setTimeout(() => {showDelete('delRep', false)}, 100)}}
              onKeyUp={() => config.title === 'ML ADMIN' ? verificationML() : verification()}
              onKeyPress={onKeyPress}
              value={repeatPassword}
              type='text'
              onChange={(e) => { setRepeatPassword(e.target.value) }}
            />
            {/* {delRep && repeatPassword ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('repeatPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
            {wrongRepeat ? (<DeleteInput style={{ bottom: '44%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : null}
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
              Les mots de passes ne correspondent pas
            </FeedBack>
          </BoxContainer>
          <BoxContainer>
            {/* {delRep && repeatPassword ? (<DeleteInput style={{ bottom: '47%', right: '3%' }} src={UiCross} onClick={() => {emptyInput('repeatPassword'); setState({ borderColorMail: '#B3ABBC', warnPsw: false })}} />) : ''} */}
            {wrongRepeat ? (<DeleteInput style={{ bottom: '44%', right: '2.5%', height: '1rem' }} src={UiDanger} />) : null}
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
              Les mots de passes ne correspondent pas
            </FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>N° d'autorisation d'enseigner</Name>
            <Input
              value={autorisation}
              onKeyPress={onKeyPress}
              onKeyUp={() => config.title === 'ML ADMIN' ? verificationML() : verification()}
              onChange={(e) => setAutorisation(e.target.value)}
            />
          </BoxContainer>
          {config.title === 'PP ADMIN' ?
            <BoxContainer>
            <Name>Type d'agence</Name>
              <EntitySelectorContainer>
                {DisplayEntitySelector()}
              </EntitySelectorContainer>
            </BoxContainer> : ''}
          <ButtonContainer>
            <Button
              disabled={!enable}
              data-type='premium'
              style={{ backgroundColor: backgroundColor, color: color, cursor: enable ? 'pointer' : 'unset' }}
              onClick={() => clickHandlerAccount()}
            >
              Créer l'agence
            </Button>
          </ButtonContainer>
        </FormContainer>
        {config.layouts.agencyCreate.logo === true
          ? <UploadInput>{renderMediaUpload()}</UploadInput>
          : null}
      </FormAndLogoContainer>
    )
  }

  const renderResults = () => {
    return (
      <MainLayout>
        <ModalConfirm
          show={show}
          text={'Attention'.toUpperCase()}
          confirm={() => setShow(false)}
          name={modalText}
          confirmText='OK'
          noCancel
        />
        {success
          ? (
            <Success>
              <ImgSuccess src={SuccessIcon} />
              <TextSuccess>
                L'agence à bien été créé !
              </TextSuccess>
            </Success>
            )
          : null}
        <Container>
          {renderTitle()}
          {renderContainer()}
        </Container>
      </MainLayout>
    )
  }

  return (
    <Bg>
      <SideBar />
      {renderResults()}
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'AgencyCreate', saga: getTokenSaga })
const arg = compose(withSaga, withConnect)(AgencyCreate)

export default arg
