import React from 'react'
import styled from 'styled-components'

const Container = styled.button`
width:1rem;
display:flex;
position:static;
display:inline;
padding-right:0.5rem;
align-self:center;
border:none;
background-color:white;
`

const Icon = styled.img`
height: 1rem;
width:1.25rem;
`

const ButtonArrowRight = (props) => {
  return (
    <Container>
      {props.icon ? <Icon src={props.icon} /> : null}
    </Container>
  )
}

export default ButtonArrowRight
