/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { DELETE_AGENCY, REFRESH_AGENCIES, GET_DASHBOARD, GET_FILTERED_AGENCIES } from '../App/constants'
import { deleteAgencySuccess, deleteAgencyError, refreshAgenciesSuccess, refreshAgenciesError, getDashboardSuccess, getDashboardError, getFilteredAgenciesSuccess, getFilteredAgenciesError } from '../App/actions'
import moment from 'moment'
import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

export function * deleteAgency ({ token, id }) {
  const urlAgencyDelete = `${serverPath}/api/agencies/${id}?access_token=${token}`
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`
  const deleteAgencyTgdApisUrl = `${serverPathTgdApi}/agencies/${id}`
  const getAgencyTgdApiUrl = `${serverPathTgdApi}/agencies?agencyId=${id}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)

  const deleteOptions = {
    method: 'DELETE',
    headers
  }
  const getOptions = {
    method: 'GET',
    headers,
  }

  try {
    if (isB2VM) {
      yield call(request, deleteAgencyTgdApisUrl, deleteOptions)
      const agencies = yield call(request, getAgencyTgdApiUrl, getOptions)
      yield put(deleteAgencySuccess(agencies))
    } else {
    // Call our request helper (see 'utils/request')
    yield call(request, urlAgencyDelete, { method: 'DELETE' })
    const agencies = yield call(request, urlAgencies, { method: 'GET' })
    yield put(deleteAgencySuccess(agencies))
    }
  } catch (err) {
    yield put(deleteAgencyError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * deleteAgencySaga () {
  yield takeLatest(DELETE_AGENCY, deleteAgency)
}

export function * refreshAgencies ({ token }) {
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`
  const getAgencyTgdApiUrl = `${serverPathTgdApi}/agencies`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)

  const getOptions = {
    method: 'GET',
    headers,
  }

  try {
    if (isB2VM) {
      const agencies = yield call(request, getAgencyTgdApiUrl, getOptions)
      yield put(refreshAgenciesSuccess(agencies))
    } else {
      const agencies = yield call(request, urlAgencies, { method: 'GET' })
      yield put(refreshAgenciesSuccess(agencies))
    }
  } catch (err) {
    yield put(refreshAgenciesError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * refreshAgenciesSaga () {
  yield takeLatest(REFRESH_AGENCIES, refreshAgencies)
}

export function * getDashboard ({ token }) {
  const dashboardUrl = `${serverPathTgdApi}/agencies/dashboard`

  if (isB2VM) {
    try {
      const dashboard = yield call(request, dashboardUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
      yield put(getDashboardSuccess(dashboard))
    } catch (err) {
      yield put(getDashboardError(err))
    }
  }
 }

 export function * getDashboardSaga () {
  yield takeLatest(GET_DASHBOARD, getDashboard)
}

/**
 * Root saga manages watcher lifecycle
 */

export function * getFilteredAgencies ({filters, token}) {
  let url = `${serverPathTgdApi}/agencies`
  
  if (filters.agency && filters.agency !== 'all') {
    url += `${url.includes('?') ? '&' : '?'}agencyName=${filters.agency}`
  }
  
  if (filters.date) {
    url += `${url.includes('?') ? '&' : '?'}dateCreation=${moment(filters.date).format('YYYY-MM-DD')}`
  }


  try {
    console.log(url)
    const agenciesResponse = yield call(request, url, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
    yield put(getFilteredAgenciesSuccess(agenciesResponse))
  } catch (err) {
    yield put(getFilteredAgenciesError(err))
  }
}

export function * getFilteredAgenciesSaga () {
  yield takeLatest(GET_FILTERED_AGENCIES, getFilteredAgencies)
}
