import styled from 'styled-components'

export const Button = styled.button`
  height: 3rem;
  right: -11rem;
  width: 220px;
  bottom: 0px;
  position: absolute;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 500;
  font-style:normal;
  text-align: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  border: unset;
  background-color: ${props => props.isDone ? '#DFF7E6' : '#EDF0F7'};
  &:hover {
    background-color: ${props => props.isDone ? '#DFF7E6' : '#AAE5F3'};
  }
`

export const ButtonText = styled.div`
  font-family: Poppins;
  font-size: 0.8rem;
  font-weight: 500;
  font-style:normal;
`