/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import moment from 'moment'
import './csvbutton.css'
import ModalConfirm from '../../components/ModalConfirm'
import ShouldRefresh from '../ShouldRefresh/index'
import { deletePlayerSaga, refreshPlayersSaga, getTokenSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deletePlayer, refreshPlayers, getToken } from '../App/actions'
import Menu from '../../images/menuBoxed.svg'
import { Link } from 'react-router-dom'
import CSVReader from 'react-csv-reader'
import Avatar1 from '../../images/Avatar1.svg'
import Avatar2 from '../../images/Avatar2.svg'
import Avatar3 from '../../images/Avatar3.svg'
import Avatar4 from '../../images/Avatar4.svg'
import AddStudent from '../../images/Add.svg'
import UiEdit from '../../images/edit.svg'
import UiDelRed from '../../images/closeRed.svg'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { ActionContainer, Progression, ProgressionBar, ProgressionBarIn, ProgressionText, ProgressionTextAdmin, ActionEdit, EditIcon, ActionArchive, DeleteIcon, Bg, Container, Import, ImportImg, ImportText, FirstContainer, ContainerFirstCSV, ContainerFirstStudent, AvatarContainer, TextFirstCSV, ButtonImport, ButtonCreate, ClickableProg } from './styledComponents'

const Players = (props) => {
  const dispatch = useDispatch()

  const players = useSelector((state) => state.toJS().global.players)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyid = useSelector((state) => state.toJS().global.agencyid)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const journeys = useSelector((state) => state.toJS().global.journeys)
  const progressions = useSelector((state) => state.toJS().global.progressions)

  // const [showPanel, setShowPanel] = useState(false)
  // const [textPanel, setTextPanel] = useState('')FIXME: maybe use react-tooltip ?
  // const [panX, setPanX] = useState('10rem')
  // const [panY, setPanY] = useState('10rem')
  const [importError, setImportError] = useState('')
  const [showModal, setShowModal] = useState()
  const [playerId, setPlayerId] = useState()
  const [modalName, setModalName] = useState('')
  const [modalText, setModalText] = useState('')

  useEffect(() => {
    dispatch(refreshPlayers(agencyToken, agencyid, isAdmin))
    // eslint-disable-next-line
  }, [])

  const findAgencyName = (agencyId) => {
    if (agencies == null) {
      return '???'
    }

    if (agencyId == null) {
      return '_none'
    }
    const agency = agencies.find(a => a.id.toString() === agencyId.toString())

    return agency != null ? agency.agencyname : 'Aucune agence'
  }

  // Get number of sequences on a journey
  const getCount = (playerId, journeyId, progressions) => {
    let count = 0
    let total = 0
    let now = 0
    progressions.forEach(prog => {
      if (prog.playerId === playerId && prog.journeyId === journeyId) {
        for (let i = 0; i < prog.sectionScores.length; i++) {
          if (prog.sectionScores[i].containsScore === true) { total++ }
          if (prog.sectionScores[i].score != null && prog.sectionScores[i].containsScore === true) { now++ }
        }
        if (now === total) { count++ }
      }
    })
    return count
  }

  // Get total number of sequences on a journey
  const getTotalCount = (playerJourneyId, journey) => {
    let count = 0
    journeys.forEach(j => {
      if (playerJourneyId === j.id) {
        count = j.sequences.length
      }
    })
    return count
  }

  const confirmDelete = (id, action, firstname) => {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  const generatePassword = () => {
    const baseStr = Math.random().toString(36).substring(2, 10)
    const splitStr = baseStr.split('')
    const finalStr = []
    for (let i = 0; i < splitStr.length; i++) {
      if (i % 2 === 0) {
        finalStr.push(splitStr[i].toUpperCase())
      } else {
        finalStr.push(splitStr[i].toLowerCase())
      }
    }
    return finalStr.join().replace(/,/g, '')
  }

  const importPlayer = (datas, fileInfo) => {
    datas.forEach(data => {
      if ((!data[0].includes('NOM') || !data[0].includes('Attention')) && data[1] && data[2]) { // Exclude the first two line
        const password = data[4] !== '' ? data[4] : generatePassword()
        dispatch(getToken({
          email: data[2],
          password: password,
          foreignKey: data[2],
          firstname: data[1],
          lastname: data[0],
          access: 'premium',
          journey: data[3],
          token: agencyToken
        }))
        dispatch(refreshPlayers(agencyToken, agencyid, isAdmin))
      } else {
        setImportError('CSV format invalid')
        setTimeout(setImportError(''), 3000)
      }
    })
  }

  const playerGrid = [
    { suppressMovable: true, headerName: 'Eleves', field: 'eleve', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden' }} to={{ pathname: `players/edit/${params.data.id}`, query: { id: params.data.id } }}>{params.data.eleve}</Link> } },
    { suppressMovable: true, headerName: 'Email', field: 'email', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true } },
    { suppressMovable: true, headerName: 'Date', field: 'date', suppressMenu: true },
    {
      suppressMovable: true,
      headerName: 'Progression',
      cellRendererFramework: (params) => {
        return (
          <Progression>
            <ClickableProg style={{ cursor: params.data.foreignKey !== 'fkeditor' ? 'cursor' : 'unset' }} to={params.data.foreignKey !== 'fkeditor' ? `players/edit/stats/${params.data.id}` : '/players'}>
              <ProgressionBar>
                <ProgressionBarIn style={{ width: `${params.data.pCount ? params.data.pCount / params.data.pTotal * 100 : 0}%` }} />
              </ProgressionBar>
              {isAdmin ? <ProgressionTextAdmin>{params.data.pCount}/{params.data.pTotal}</ProgressionTextAdmin> : <ProgressionText>{params.data.pCount}/{params.data.pTotal}</ProgressionText>}
            </ClickableProg>
          </Progression>
        )
      }
    }
  ]

  const agencyGrid = { suppressMovable: true, headerName: 'Agence', field: 'agence', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return <Link style={{ display: 'block', textOverflow: 'ellipsis', overflow: 'hidden', cursor: params.data.agence === 'Aucune agence' ? 'unset' : 'pointer ' }} to={{ pathname: params.data.agence === 'Aucune agence' ? '' : `agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>{params.data.agence}</Link> } }

  const actionsGrid = [
    {
      suppressMovable: true,
      width: 100,
      suppressMenu: true,
      field: 'Actions',
      cellRendererFramework: (params) => {
        return (
          <ActionContainer>
            <Link to={`players/edit/${params.data.id}`}>
              <ActionEdit>
                <EditIcon src={UiEdit} />
              </ActionEdit>
            </Link>
            {/* <ActionArchive onMouseEnter={() => { showPanel(true, 'Supprimer') }} onMouseLeave={() => showPanel(false, '')} onClick={() => confirmDelete(id, 'Vous allez supprimer', name)}> */}
            <ActionArchive onClick={() => confirmDelete(params.data.id, 'Supprimer', params.data.eleve)}>
              <DeleteIcon
                src={UiDelRed}
              />
            </ActionArchive>
          </ActionContainer>
        )
      }
    }
  ]

  const adminGrid = () => {
    playerGrid.splice(2, 0, agencyGrid)
    return playerGrid.concat(actionsGrid)
  }

  const gridOptions = {
    defaultColDef: { filter: true },
    columnDefs: isAdmin ? adminGrid() : playerGrid.concat(actionsGrid)
  }

  const buildRows = () => {
    const rowData = []
    if (!players) { return null }
    players.forEach((player) => {
      let pCount = 0
      let pTotal = 0

      if (player.foreignKey === 'fkeditor') { // this is how we hide the editor...
        return null
      }

      let playerJourneyId = player.journeys

      if (playerJourneyId) {
        playerJourneyId = player.journeys.toString()

        let journeys

        pCount = getCount(player.id, playerJourneyId, progressions)
        pTotal = getTotalCount(playerJourneyId, journeys) || 0

        pCount = getCount(player.id, playerJourneyId, progressions)
        pTotal = getTotalCount(playerJourneyId, journeys) || 0
      }
      rowData.push({
        eleve: player.firstname + ' ' + player.lastname,
        foreignKey: player.foreignKey,
        email: player.email,
        agence: findAgencyName(player.agencyId),
        agencyId: player.agencyId,
        id: player.id,
        date: player.created_at !== null ? moment(player.created_at).format('DD/MM/YYYY') : player.eval.endDate.format('DD/MM/YYYY'),
        pCount: pCount,
        pTotal: pTotal
      })
    })
    return rowData
  }

  if (players == null) {
    dispatch(refreshPlayers(agencyToken, agencyid, isAdmin))
    return (<div>LOADING</div>)
  }

  return (
    <Bg>
      <ShouldRefresh {...props} />
      <ModalConfirm show={showModal} confirmText='Supprimer' text={modalText} name={modalName} confirm={() => { dispatch(deletePlayer(agencyToken, playerId, agencyid)); setShowModal(false) }} cancel={() => setShowModal(false)} />
      {/* {showPanel ? <HoverPanel style={{ top: panY, left: panX }}>{textPanel}</HoverPanel> : ''} FIXME: use react-tooltip */}
      <Container style={{ marginLeft: isAdmin ? '2%' : '10%' }} className={isAdmin ? 'admingrid' : 'playergrid'}>
        {isAdmin && players.length > 0
          ? <div style={{ marginBottom: '5rem' }}>
            <Import>
              <ImportImg src={Menu} />
              <ImportText>Importer</ImportText>
              <CSVReader
                cssClass='csv-reader-input'
                cssInputClass='csv-input'
                cssLabelClass='csv-label'
                label={importError}
                onFileLoaded={(datas, fileInfo) => importPlayer(datas, fileInfo)}
                inputId='Import'
              />
            </Import>
          </div>
          : null}
        {players.length > 0
          ? <div className='ag-theme-alpine' style={{ height: 600, width: 1302 }}>
            <AgGridReact
              rowData={buildRows()}
              gridOptions={gridOptions}
            />
          </div>
          : <FirstContainer>
            <ContainerFirstCSV>
              <AvatarContainer>
                <img alt='avatar1' src={Avatar1} />
                <img alt='avatar2' src={Avatar2} style={{ marginLeft: '-20px', zIndex: 0 }} />
                <img alt='avatar3' src={Avatar3} style={{ marginLeft: '-20px' }} />
              </AvatarContainer>
              <TextFirstCSV>
                Vous pouvez <b>importer des élèves</b> à l'aide d'un fichier .csv
              </TextFirstCSV>
              <ButtonImport>
                <Import>
                  <ImportImg src={Menu} />
                  <ImportText>Importer</ImportText>
                  <CSVReader
                    cssClass='csv-reader-input'
                    cssInputClass='csv-input'
                    cssLabelClass='csv-label'
                    label={importError}
                    onFileLoaded={(datas, fileInfo) => importPlayer(datas, fileInfo)}
                    inputId='Import'
                  />
                </Import>
              </ButtonImport>
            </ContainerFirstCSV>
            <ContainerFirstStudent>
              <AvatarContainer>
                <img alt='avatar4' src={Avatar4} />
              </AvatarContainer>
              <TextFirstCSV>Vous pouvez <b>créer un élève</b> dans votre console de gestion</TextFirstCSV>
              <ButtonImport>
                <ButtonCreate to='/players/create' style={{ width: '173px' }}>
                  <ImportImg src={AddStudent} />
                  <ImportText>Créer un élève</ImportText>
                </ButtonCreate>
              </ButtonImport>
            </ContainerFirstStudent>
          </FirstContainer>}
      </Container>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'PlayerDelete', saga: deletePlayerSaga })
const withRefreshSaga = injectSaga({ key: 'refreshPlayers', saga: refreshPlayersSaga })
const withCreateSaga = injectSaga({ key: 'createPlayers', saga: getTokenSaga })
const arg = compose(withSaga, withRefreshSaga, withCreateSaga, withConnect)(Players)

export default arg
