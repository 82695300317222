/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import AWS from 'aws-sdk'
import PopUpUpload from '../../components/PopUpUpload/index'
import PopUp from '../../components/PopUp'
import { awsDelete } from '../../lib/awsBasicOperations.js'
import sleep from '../../lib/sleep.js'
import UiUploadImg from '../../images/UiUploadImg.svg'
import UiUploadVid from '../../images/UiUploadVid.svg'
import closeSvg from '../../images/close.svg'
import awsUrl from '../../lib/awsAccess'
import config from '../../config/configurator'

AWS.config.credentials = {
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID_EDITOR,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY_EDITOR,
  region: "eu-west-3"
}

// border: 1px solid #B3ABBC;
const Container = styled.div`
  width:${props => props.width || '25rem'};
`

const ContainerImg = styled.div`
  width:100%;
  height:${props => props.height || '15rem'};
  display:flex;
  justify-content:center;
  position:relative;
  align-items:center;
  background-image: ${props => `url('${props.backImg}')` || null};
  background-color:${props => props.bgColor || '#EFF0F6'};
  background-size:cover
`

const NameImg = styled.div`
  background-color:#EFF0F6;
  width:100%;
  display:flex;
  justify-content:center;
  align-items:center;
`

const BtnUpload = styled.label`
  position:relative;
  background-color: #FFCA46;
  border-radius: 10px;
  display:flex;
  justify-content:center;
  align-items:center;
  width: ${props => props.size || '6rem'};
  height: 2rem;
  transition:all 0.2s ease;
  cursor: pointer;
  &:hover {
    background-color:#FFE299;
  }
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  position: absolute;
  visibility: hidden;
`

const IconUpload = styled.img`
  width:0.8rem;
  height:0.8rem;
  margin-right:0.5rem
`

const TextBtnUpload = styled.span`
  font-family: Poppins;
  font-size:0.7rem;
  font-weight:500
`

const CloseButton = styled.div`
  width: ${props => props.small ? '1.9rem' : '1.9rem'};
  height: ${props => props.small ? '1.9rem' : '1.9rem'};
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  top:${props => props.small ? '0rem' : '0.4rem'};
  right:${props => props.small ? '-1.9rem' : '0.5rem'};
  background-color:white;
  border: 1px solid #EFF0F6;
  border-radius:6px;
  &:hover{
    background-color: #FFCA46;
  }
`
const ImgClose = styled.img`
  width: 0.6rem;
  height: 0.6rem;
`

export class ButtonUploadAws extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showPopUp: { status: 'none', message: null },
      showPopUpDelete: false
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    // this will avoid reloading the preview image at every page state change
    if (this.state !== nextState) return true
    return (nextProps.prevFilename !== this.props.prevFilename)
  }

  fd (input) {
    let folder = input
    if (folder !== '') {
      if (folder.charAt(0) === '/') {
        folder = folder.substr(1, folder.length)
      }
      if (folder.slice(-1) !== '/') {
        folder = folder + '/'
      }
    }
    return folder
  }

  async uploadMe (e) {
    // a few const
    const s3 = new AWS.S3()
    const fileName = (Date.now() + '-' + e.target.files[0].name).replace(/ /g, '')
    const file = e.target.files[0]
    const myBucket = config.bucket.player.bucket
    // const myRegion = config.bucket.player.region
    // fd corrects the folder name if the '/' is at the wrong place
    const folder = this.fd(this.props.folder)

    const regex = /^[a-zA-Z0-9\(._-]+$/g
    if (!regex.test(fileName)) {
      this.setState({ showPopUp: { status: 'waitToClick', message: 'Pas de caracères spéciaux dans le nom' } })
      return
    }

    if (!this.props.isVideo && fileName.indexOf('.mp4') !== -1) {
      this.setState({ showPopUp: { status: 'waitToClick', message: 'Votre media ne peut pas être une vidéo :(' } })
      return
    }

    // # # # # # # # # # # # # # # 1 - UPLOAD FILE IN AWS CLIENT BUCKET # # # # # # # # # # # # # # # # # # # # #
    this.setState({ showPopUp: { status: 'loading', message: 'chargement nouveau fichier' } })
    const uploadParams = {
      Key: folder + fileName,
      Body: file,
      Bucket: myBucket
    }
    // note : await on s3.upload won't work, that's why we use the await Promise trick
    const upload = await new Promise((resolve, reject) => {
      s3.upload(uploadParams, (err, data) => {
        if (err) {
          console.log('media upload failure :(', err)
          resolve(false)
        } else {
          console.log('media upload success :)', data.Location)
          resolve(true)
        }
      })
    })
    // upload can be short. let's give the impression it's done im human time
    await sleep(1000)

    // if loading is in error exit this function and give the info in the pop up
    if (!upload) {
      this.setState({ showPopUp: { status: 'waitToClick', message: 'Erreur lors du chargement :(' } })
      return
    }

    // # # # # # # # # # # # # # # 3 - DO WHATEVER HAS TO BE DONE WITH THE FILENAME (like saving it in questions.json) # # # # # # # # # #
    this.props.onUpload(fileName)
    this.setState({ showPopUp: { status: 'close' } })
  }

  // called when clic on the X to delete files.
  async deleteMe () {
    this.setState({ showPopUpDelete: false })
    // start loading pop up
    this.setState({ showPopUp: { status: 'loading', message: 'suppression ancien(s) fichier(s)' } })
    const myBucket = config.bucket.player.bucket
    // fd corrects the folder name if the '/' is at the wrong place
    const folder = this.fd(this.props.folder)
    await awsDelete(myBucket, folder + this.props.prevFilename)
    // let's put a sleep here to give the user the impression that deletion took 1s (otherwise not visible in the pop up)
    await sleep(1500)
    // onDelete is a prop passed that generally sets the question video or image parameter to null
    this.props.onDelete()
    this.setState({ showPopUp: { status: 'close' } })
  }

  // # # # # # # # # # # # # # # # # # # GENERAL RENDER # # # # # # # # # # # # # # # # # # # # #
  render () {
    const prvFl = this.props.prevFilename
    // source image to show if prevFilename is defined :
    let sourceImg
    if (prvFl) {
      sourceImg = this.fd(this.props.folder) + prvFl
    }
    return (
      <Container width={this.props.width}>
        {/* This is the popup when we upload */}
        <PopUpUpload
          showPopUp={this.state.showPopUp}
          resetPopUp={() => {
            this.setState({ showPopUp: { status: 'none', message: null } })
          }}
        />
        {/* This is the popup when we Delete */}
        {this.state.showPopUpDelete &&
          <PopUp
            text='Etes vous sur'
            textYES='OUI'
            callbackYes={() => {
              this.deleteMe()
            }}
            textNO='NON'
            callbackNo={() => this.setState({ showPopUpDelete: false })}
            // icon={UiUpload}
          />}
        <ContainerImg backImg={awsUrl(sourceImg)} showImport={!prvFl} height={this.props.height} bgColor={this.props.bgColor}>
          {!prvFl
            ? <BtnUpload size={this.props.buttonSize}>
              <IconUpload src={this.props.isVideo ? UiUploadVid : UiUploadImg} />
              <TextBtnUpload>{this.props.text || 'Importer'}</TextBtnUpload>
              <Input
                type='file'
                onChange={e => {
                  e.preventDefault()
                  this.uploadMe(e)
                }}
              />
              </BtnUpload>
            : <CloseButton
                small={this.props.small} onClick={() => {
                  this.setState({ showPopUpDelete: true })
                }}
              >
              <ImgClose src={closeSvg} />
              </CloseButton>}
        </ContainerImg>
        {prvFl && !this.props.small && <NameImg>{prvFl}</NameImg>}
      </Container>

    )
  }
}
// # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # # #

export default ButtonUploadAws
