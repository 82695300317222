import styled from 'styled-components'

export const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 114vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const Container = styled.div`
  background-color: white;
  width: 474px;
  height: 516px;
  font-family: Poppins;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
`

export const TextContent = styled.div`
  width: calc(100% - 102px);
  margin-top: 44px;
  margin-left: 56px;
  text-align: left;
`

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

export const Description = styled.div`
  margin-top: 10px;
  font-size: 16px;
  line-height: 24px;
  font weight: 300;
`

export const Name = styled.div`
  margin-top: 12px;
  margin-bottom: 25px;
  width: 60%;
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  `
export const Icon = styled.img`
  height: 80%;
  margin-top: 0;
  margin-right: 4%;
  margin-bottom: 0%;
`

export const InputTitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #808080;
`

export const InputField = styled.input`
  margin-top: 8px;
  margin-bottom: 24px;
  width: 264px;
  height: 38px;
  border: 1px solid #B3ABBC;
  border-radius: 10px;
  padding-left: 1rem;
  font-size: 16px;
  &:focus {outline:none;}
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
}

@-webkit-keyframes fadein{from{opacity:0}
to{opacity:1}
}
`

export const InputFieldDate = styled.input`
  font-family: Poppins;
  margin-top: 8px;
  margin-bottom: 24px;
  width: 264px;
  height: 38px;
  border: 1px solid #B3ABBC;
  padding-left: 1rem;
  border-radius: 10px;
  font-size: 16px;
  &:focus {margin-bottom: 24px; outline:none;}
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
}
::-webkit-calendar-picker-indicator {
  margin-right: 1rem;
  cursor: pointer;
}

@-webkit-keyframes fadein{from{opacity:0}
to{opacity:1}
}
`

export const ContentButton = styled.div`
  height: 40px;
  margin-top: 48px;
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
`

export const ButtonCancel = styled.button`
  height: 38px;
  width: 88px;
  background-color: white;
  border: solid 0.1px grey;
  border-radius: 0.5rem;
  color: #868383;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }
`

export const ButtonAction = styled.button`
  height: 38px;
  width: 108px;
  background-color: #61D7F3;
  color: black;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight 500;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.14);
  border: none;

  &:hover {
    cursor: pointer;
  }
`
