const strize = require('../strize')

module.exports = {
  title: 'ADMIN',
  imgs: {
    root: '../../images'
  },
  home: '/players',
  features: {
    elearning: true,
    simu: true,
    eval: true
  },
  bucket: {
    player: {
      bucket: strize(''),
      region: strize('')
    },
    editor: {
      bucket: strize(''),
      region: strize(''),
      key: strize(''),
      secret: strize('')
    }
  },
  colors: {
    main: '#0072bc'
  },
  layouts: {
  }
}
