import styled from 'styled-components'
import Select from 'react-select'

export const Container = styled.div`
width:85%;
display: flex;
justify-content: center;
padding-top: 100px;
`

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 5rem;
`

export const Button = styled.button`
  float: right;
  right:0;
  width: 8.25rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style:normal;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  margin-top: 1rem;
  border: unset;
`

export const MainLayout = styled.div`
float: right;
display: flex;
flex-direction: column;
width: 80%;
background-color: white;
`

export const FormContainer = styled.div`
  margin:auto;
  position:relative;
  margin-top:3rem;
  margin-left: 40%;
  width:40%;
`

export const BoxTitle = styled.div`
font-family:Poppins;
font-weight:400;
font-size:0.75rem;
font-style:normal;
color:#4F4F4F;
margin-top: 0.6rem;
margin-left: 1rem;
`
export const FeedBack = styled.div`
  color: red;
  margin-left: 1rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: 0.1rem;
`

export const BoxContainer = styled.div`
display:flex;
flex-direction:column;
width: 100%;
position: relative;
height: 85px;
`
export const Input = styled.input`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  height: 2.375rem;
  margin-top: 0.4rem;
  padding-left: 1rem;
  &:focus {outline:none;}
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
}

@-webkit-keyframes fadein{from{opacity:0}
to{opacity:1}
}
`
export const LicenceSelector = styled(Select)`
.react-select__control {
  border-radius: 10px;
  width: 100%;
  color:black;
  font-family:Poppins;
  font-size:13px;
  outline: none;
  border: 1px solid #B3ABBC;
  height: 2.75rem;
  cursor: pointer;
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  color: black;
}
  & .react-select__control--is-focused {box-shadow: 0px 0px 3px 3px #00C4F3;}
`
export const ArrowLicence = styled.img`
  position: absolute;
  width: 12px;
  top: 58%;
  z-index: 10;
  right: 3%;
  pointer-events: none;
`
export const SelectorLicenceContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`

export const Opt = styled.option`
  padding-left: 3rem;
`

export const Name = styled.div`
font-family:Poppins;
font-weight:300;
font-size:0.8rem;
font-style:normal;
margin-left: 1rem;
color:#4F4F4F;
`

export const Success = styled.div`
  position: absolute;
  z-index: 10;
  top: 12rem;
  left: 25rem;
  height: 9rem;
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #FFFCD3;
  font-family: Poppins;
`
export const TextSuccess = styled.div`
  margin-top: 1.5rem;
  font-size: 1.1rem;
`
export const ImgSuccess = styled.img`
  margin-top: 1rem;
  height: 4rem;
`

export const DeleteInput = styled.img`
  z-index: 10;
  position: absolute;
  height: 0.6rem;
  widht: 0.6rem;
  &:hover {cursor: pointer;}
`
