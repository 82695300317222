import styled from 'styled-components'
import { Link } from 'react-router-dom'
import UiBoy from '../../images/boy.svg'
import statsLogo from '../../images/Stats.svg'
import Select from 'react-select'

export const DashboardContainer = styled.div`
  display: flex;
  margin-bottom: -50px;
  padding-top: 30px;
  justify-content: center;
  position: relative;
`

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const MainContainer = styled.div`
margin:auto;
position:relative;
width:35%;
padding-top:100px;
`
export const SkillsContainer = styled.div`
width:100%;
border:1px solid #979797;
border-radius:10px;
margin-top:2.5rem;
padding-bottom:1rem;
`
export const SkillTitle = styled.div`
padding-left:2rem;
font-family: Poppins;
font-style:normal;
font-size:1rem;
font-weight:300;
margin-top:1.5rem;
`
export const Score = styled.div`
position:absolute;
color:#024179;
left:86.8%;
margin-top:-0.25rem;
font-family:Poppins;
font-style:normal;
font-size:1rem;
font-weight:500;
@media(max-width:1024px)
  {
    left:66.2%;
  }
`
export const MainLayout = styled.div`
float: right;
display: flex;
flex-direction: column;
width: 80%;
background-color: white;
`

export const StatsRow = styled.div`
display:flex;
flex-direction:row;
margin-top:2.5rem;
`
export const StatsRowKM = styled.div`
display:flex;
flex-direction:row;
margin-top:0.5rem;
`
export const StatsTitle = styled.div`
width:60%;
height:5%;
text-align:left;
border:1px solid #979797;
overflow: hidden;
white-space: nowrap;
border-radius:10px;
line-height:4rem;
padding-left:5%;
font-size: 95%;
font-family: Poppins;
`
export const StatsTime = styled.div`
width:40%;
height:4rem;
text-align:center;
background-color:#EDF0F7;
color:#024179;
border-radius:10px;
line-height:4rem;
font-family:Poppins;
flex-wrap: wrap;
font-weight:700;
margin-left:0.5rem;
font-size:1rem;
`

export const StatsBox = styled.div`
display:flex;
flex-direction:row;
flex-wrap: wrap;
text-align:center;
margin-top:2%;
margin-left:5%;
width: 100%;

`
// @media(width > 600px){
//   {
//     display:flex;
//   }
// }
// @media(width < 600px){
//   {
//     display: none
//   }
// }

export const BoxTitle = styled.div`
width: 25%;
font-family:Poppins;
font-style:normal;
font-size:1rem;
font-weight:300;
margin-left:1.2rem;
`

// export const StatsBox = styled.div`
// display:flex;
// flex-direction:row;
// text-align:center;
// margin-top:2%;
// margin-left:5%;
//  `

// export const BoxTitle = styled.div`
// font-family:Poppins;
// font-style:normal;
// font-size:1rem;
// font-weight:300;
// margin-left:1rem;
// `
// export const StatsBox2 = styled.div`
// display:flex;
// flex-direction:row;
// text-align:center;
// top:10rem;
// `

export const Percent = styled.div`
z-index:100;
color:#024179;
font-weight:700;
font-family:Poppins;
width:100%;
height:2rem;
position:relative;
bottom: 39%;
`

// export const Percent = styled.div`
// z-index:100;
// color:#024179;
// font-weight:700;
// font-family:Poppins;
// width:5rem;
// height:2rem;
// position:absolute;
// margin-left:0.75rem;
// margin-top:-4rem;
// `
export const SkillsContainer2 = styled.div`
  width:100%;
  height:19.5rem;
  border:1px solid #979797;
  border-radius:10px;
  margin-top:2.8rem;
`
export const Opt = styled.option`
  padding-left: 3rem;
`

export const SelectorContainer = styled.div`
  text-align:center;
  margin-bottom: 2.3rem;
  position: relative;
  width: 100%;
`
export const Selector = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 100%;
  padding-left: 2.25rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-image: url(${UiBoy});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

export const ArrowStudent = styled.img`
  position: absolute; 
  width: 12px;
  z-index: 10;
  top: 78%;
  left: 94.3%;
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 400;
  style: normal;
  width: 100%;
  margin: auto;
  margin-top: 5rem;
  border-bottom: 1px solid #979797;
  cursor:pointer;
`

export const Info = styled(Link)`
  height:41px;
  cursor: pointer;
  text-decoration: none;
  font-family:Poppins;
  color: black;
  flex: 1;
`

export const Parcours = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: black;
  font-family:Poppins;
  flex: 1;
`

export const Stats = styled.div`
  cursor: pointer;
  text-decoration: none;
  color: #166dd8;
  font-family:Poppins;

  & {
    border-bottom: 3px solid #166dd8;
  }
  flex: 1;
`
export const SelectorStatsContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
`

export const ArrowStats = styled.img`
  width: 12px;
  position: absolute;
  right: 3%;
  top: 18.4%;
  z-index: 100;
  pointer-events: none;
`

export const StatsSelector = styled(Select)`
.react-select__control {
  border-radius: 10px;
  width: 100%;
  color:black;
  font-family:Poppins;
  font-size:13px;
  outline: none;
  border: 1px solid #B3ABBC;
  height: 2.75rem;
  cursor: pointer;
  padding-left: 2.25rem;
  background-image: url(${statsLogo});
  background-size: 1.1rem;
  background-position: top 45% left 1rem;
  background-repeat: no-repeat;
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  color: black;
}
  & .react-select__control--is-focused {box-shadow: 0px 0px 3px 3px #00C4F3;}
`
