import React, { useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import '../../fonts/fonts.css'
import SideBar from '../SideBar'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import { changePasswordData } from './saga'
import { changePassword } from '../App/actions'
import MainTitle from '../../components/MainTitle'
import pen from '../../images/pen.svg'
import UiDanger from '../../images/UiWarn.svg'
import SuccessIcon from '../../images/successface.svg'
import { ButtonContainer, DeleteInput,FeedBack, Success, ImgSuccess, TextSuccess, Bg, MainLayout, FormContainer, BoxContainer, Name, Input, InputDisplayer, Button } from './styledComponents'

// import { refreshEPlayersSaga, refreshEPlayers } from '../RefreshPlayers/saga'

const PlayerTransferAccount = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const players = useSelector((state) => state.toJS().global.simuPlayers)
  const token = useSelector((state) => state.toJS().global.agencytoken)

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const player = players ? players.find(player => player._id === id) : ''

  const [success, setSuccess] = useState(false)
  const [password, setPassword] = useState()
  const [repPassword, setRepPassword] = useState()
  const [email, setEmail] = useState(player.email)
  const [verif, setVerif] = useState(false)
  // const [borderColor, setBorderColor] = useState()
  const [wrongRepeat, setWrongRepeat] = useState('')

  function handleClick () {
    dispatch(changePassword({ id, password, token }))
    setPassword('')
    setRepPassword('')
    setVerif(false)
    setSuccess(true)
    setTimeout(() => {
      setSuccess(false)
      history.push('/simuplayers')
    }, 3000)
  }

  function verification () {
    if (email && (password === repPassword)) {
      if(password && repPassword !== undefined){
      setVerif(true)
    }
    } else {
      setVerif(false)
    }
  }
  
  const passwordCheck = () => {
    if (!repPassword) {
      // setBorderColor({ psw: '#B3ABBC' })
      setWrongRepeat(false)
      return true
    }
    if (repPassword !== password) {
      // setBorderColor({ psw: '#FF0000' })
      setWrongRepeat(true)
      return false
    } else {
      // setBorderColor({ psw: '#B3ABBC' })
      setWrongRepeat(false)
      return true
    }
  }

  const handleChangePassword = useCallback ((event) => {
    const pass = event.target.value
    setPassword(pass)
  }, [])

  const handleChangeRepPassword = useCallback ((event) => {
    const repPass = event.target.value
    setRepPassword(repPass)
  }, [])

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      if (verif) { handleClick() }
    }
  }

  function renderSuccess () {
    return (
      <Success>
        <ImgSuccess src={SuccessIcon} />
        <TextSuccess>L'élève à bien été transféré !</TextSuccess>
      </Success>
    )
  }

  if (!player.firstname) {
    return <div>LOADING</div>
  }

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        {success
          ? renderSuccess()
          : <div />}
        <MainTitle text='Transfert' icon={pen} />
        <FormContainer>
          <BoxContainer>
            <Name>Nom</Name>
            <InputDisplayer>
              <Input
                style={{ backgroundColor: '#F5F5F5' }}
                readOnly
                disabled
                autocomplete='off'
                onKeyUp={() => verification()} value={player.lastname}
              />
            </InputDisplayer>
          </BoxContainer>
          <BoxContainer>
            <Name>Prénom</Name>
            <InputDisplayer>
              <Input
                style={{ backgroundColor: '#F5F5F5' }}
                readOnly
                disabled
                autocomplete='off'
                onKeyUp={() => verification()} value={player.firstname}
              />
            </InputDisplayer>
          </BoxContainer>
          <BoxContainer>
            <Name>Email</Name>
            <InputDisplayer>
              <Input
                style={{ backgroundColor: '#F5F5F5' }}
                readOnly
                disabled
                autocomplete='off'
                onKeyUp={() => verification()} value={email}
                onChange={(e) => { setEmail(e.target.value) }}
              />
            </InputDisplayer>
          </BoxContainer>
          <BoxContainer>
            <Name>Mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyUp={() => {verification(); passwordCheck()}}
                onKeyPress={onKeyPress}
                value={password}
                type='text'
                onChange={(e) => handleChangePassword(e)}
              />
            </InputDisplayer>
            {wrongRepeat
            ? <DeleteInput style={{ bottom: '35.5%', right: '2.5%', height: '1rem' }} src={UiDanger} />
            : ''}
          </BoxContainer>
          <BoxContainer>
            <Name>Répeter le mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyUp={() => {verification(); passwordCheck()}}
                onKeyPress={onKeyPress}
                value={repPassword}
                type='text'
                onChange={(e) => handleChangeRepPassword(e)}
              />
            </InputDisplayer>
            {wrongRepeat
            ? <DeleteInput style={{ bottom: '18%', right: '2.5%', height: '1rem' }} src={UiDanger} />
            : ''}
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
              Les mots de passes ne correspondent pas
            </FeedBack>
          </BoxContainer>
          <ButtonContainer>
            <Button disabled={!verif} data-type='premium' style={{ backgroundColor: verif ? '#00C4F3' : '#EFF0F6', color: verif ? 'black' : '#868383', cursor: verif ? 'pointer' : 'unset' }} onClick={() => handleClick()}>
              Transférer le compte
            </Button>
          </ButtonContainer>
        </FormContainer>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'changePassword', saga: changePasswordData })

const arg = compose(withSaga, withConnect)(PlayerTransferAccount)

export default arg
