import styled from 'styled-components'
import { Link } from 'react-router-dom'
import config from '../../config/configurator'
import datePickerIcon from '../../images/datePickerIcon.svg'

export const HeaderContainer = styled.div`
  align-items: center;
  width: 100%;
  margin: 1rem 0 1rem 0;
  justify-content: center;
  display: flex;
`

export const FilterContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 32.5px;
`

export const TopHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const FilterSubContainer = styled.div`
  margin: 1rem 0 1rem 0;
  display: flex;
  align-items: center;
  width: 535px;
  height: 40px;
  border-radius: 5px;
  position: relative;
`

export const ButtonContainer = styled.div`
  position: absolute;
  right: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 31px;
  width: 80px;
  z-index: 2;
`

export const ResetButtonContainer = styled.div`
  display: flex;
  height: 30.72px;
  width: 30.72px;
  border: 1px solid ligtgray;
  border-radius: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #AAE5F3;
  }
`

export const ValidateButtonContainer = styled.div`
  display: flex;
  height: 30.72px;
  width: 30.72px;
  border: 1px solid ligtgray;
  border-radius: 5px;
  background-color: white;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    background-color: #AAE5F3;
  }
`

export const IconImg = styled.img`
  height: 0.7vw;
  padding-left: 5px;
  position: absolute;
  left: 5px;
`

export const InputContainer = styled.div`
  height: 1.9rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background-color: white;
  margin-left: 5px;
  position: relative;
  width: 244px;
`

export const Input = styled.input`
  height: 100%;
  display: flex;
  font-family:Poppins;
  color: black;
  width: 100%;
  padding-left: 26px;
  font-size: 13px;
  border: 1px solid lightgray;
  box-sizing: content-box;
  border-radius: 5px;
  outline-color: #2684FF;
  cursor: pointer;
  &:focus {
    box-sizing: content-box;
    border-radius: 5px;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: black;
  }
  :-ms-input-placeholder {
     color: black;
  
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
`
export const Loader = styled.div`
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #024179;
  width: 3rem;
  height: 3rem;
  margin-top:1rem;
  font-family:Poppins;
  animation: spin 1s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`

export const ActionEdit = styled.div`
  display: flex;
  position:absolute;
  top:20%;
  left:15%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;
  margin-left: -4px;
  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`
export const ActionArchive = styled.div`
  margin-left: 8px;
  display: flex;
  position:absolute;
  top:20%;
  left:46%;
  flex-direction: center;
  justify-content: center;
  align-items: center;
  height: 1.6vw;
  min-width: 1.6vw;
  border-radius: 0.225rem;
  cursor: pointer;

  &:hover {
    background-color: #AAE5F3;
  }
  border: ${config.colors.ActionMail};
  background-color: white;
`

export const EditIcon = styled.img`
height: 0.8vw;
object-fit: content;
`

export const DeleteIcon = styled.img`
  height: 0.7vw;
`

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x:hidden;
`

export const LayoutContainer = styled.div`
  height:100%;
  width:70vw;
  margin-left:1.8rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items: center;
  position: relative;
  margin-top: 2.5rem;
`

export const ClickableName = styled(Link)`
  color: black;
  cursor: pointer;
`

export const FirstContainer = styled.div`
  width: 70vw;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerFirstAgency = styled.div`
  width: 377px;
  height: 322px;
  display: flex;
  flex-direction: column;
  border: 1px solid #9C9C9D;
  box-sizing: border-box;
  box-shadow: 4px 4px 9px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  margin-right: 1.5rem;
`

export const AvatarContainer = styled.div`
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const TextFirstCSV = styled.p`
  text-align: center;
  margin: 10px auto;
  margin-top: 1.3rem;
  width: 85%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 140%;
`

export const ButtonImport = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  margin-top: 2.25rem;
`

export const ButtonCreate = styled(Link)`
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #979797;
  background-color:#ffffff;
  color:black;
  font-family:Poppins;
  font-style:normal;
  font-size:1rem;
  margin-bottom:1rem;
  line-height:3rem;
  box-shadow: 0px 2px 7px rgba(0,0,0,0.14);
  position: absolute;
`

export const ImportImg = styled.img`
  width:1rem;
  height:1rem;
  display: flex;
  position: absolute;
  left: 1rem;
`

export const ImportText = styled.div`
  font-size: 16px;
  color: black;
  width: 100%;
  position: absolute;
  left: 2rem;
  margin-left: 8px;
`

export const FilterGridContainer = styled.div`
  height: 2.65rem;
  position: absolute;
  top: 49px;
  display: flex;
  z-index: 1;
  align-items: center;
  width: 53.5%;
  margin-left: 21.2%;
`

export const DateFilter = styled.input`
  z-index: 1;
  width: 177px;
  height: 1.9rem;
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 5px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 300;
  font-size: 11px;
  padding-left: 12px;
  margin-left: 5px;
  color: black;

  &:focus {
    outline-color: #297fcc;
  }
  ::-webkit-calendar-picker-indicator {
    background: url(${datePickerIcon}) no-repeat;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 4px 2px 0px 0px;
}
`