/**
 * Gets the repositories of the user from Github
 */

 import { call, put, takeLatest } from 'redux-saga/effects'
 import { DELETE_ARCHIVE, GET_ARCHIVE_PLAYERS, REVERT_ARCHIVE_PLAYER } from '../App/constants'
 import { deleteArchiveSuccess, deleteArchiveError, getArchivePlayersSuccess, getArchivePlayersError, revertArchivePlayerError } from '../App/actions'
 
 import request from '../../utils/request'
 
 const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
 const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

 const getArchiveStudents = (arr) => {
  var i = 0;
  while (i < arr.length) {
    if (arr[i].status === undefined || arr[i].status === 0 || arr[i].status === 1) {
      arr.splice(i, 1)
    } else {
      ++i
    }
  }
  return arr
}
 
 export function * getArchivePlayers ({ token }) {
  const archiveTgdApiUrl = `${serverPathTgdApi}/students`

  if (isB2VM) {
    try {
      // Call our request helper (see 'utils/request')
      const archiveStudents = yield call(request, archiveTgdApiUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
      console.log(getArchiveStudents(archiveStudents))
      yield put(getArchivePlayersSuccess(getArchiveStudents(archiveStudents)))
  
      // yield put(deleteSimuPlayerSuccess(/* players */)) no redux action for now
    } catch (err) {
      yield put(getArchivePlayersError(err))
    }
  }
 }

 export function * getArchivePlayerSaga () {
  yield takeLatest(GET_ARCHIVE_PLAYERS, getArchivePlayers)
}
 
 export function * deleteArchive ({ token, id }) {
  const archiveTgdApiUrl = `${serverPathTgdApi}/students`
   const urlTgdApiPlayerDelete = `${serverPathTgdApi}/students?studentId=${id}`
 
   const headers = new window.Headers()
   headers.append('Content-Type', 'application/json')
   headers.append('Accept', 'application/json, text/plain, */*')
   headers.append('Authorization', `Bearer ${token}`)
   const options = {
     method: 'DELETE',
     headers
   }

   try {
    yield call(request, urlTgdApiPlayerDelete, options)
      const archiveStudents = yield call(request, archiveTgdApiUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
      yield put(deleteArchiveSuccess(getArchiveStudents(archiveStudents)))
   } catch (err) {
     yield put(deleteArchiveError(err))
   }
 }
 
 /**
  * Root saga manages watcher lifecycle
  */
 export function * deleteArchiveSaga () {
   yield takeLatest(DELETE_ARCHIVE, deleteArchive)
 }

 export function * revertArchivePlayer ({ id, token }) {
  const patchStudents = `${serverPathTgdApi}/students/${id}`
  const archiveTgdApiUrl = `${serverPathTgdApi}/students`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Accept', 'application/json, text/plain, */*')
  headers.append('Authorization', `Bearer ${token}`)

  const body = JSON.stringify({
    status: 1
  })

  const patchOptions = {
    method: 'PATCH',
    headers,
    body
  }


  try {
    yield call(request, patchStudents, patchOptions)
    const archiveStudents = yield call(request, archiveTgdApiUrl, { method: 'GET', headers: {'Authorization': `Bearer ${token}`} })
    yield put(getArchivePlayersSuccess(getArchiveStudents(archiveStudents)))
  } catch (err) {
    console.log(err)
    yield put(revertArchivePlayerError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * revertArchivePlayerSaga () {
  yield takeLatest(REVERT_ARCHIVE_PLAYER, revertArchivePlayer)
}
 