import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import '../../fonts/fonts.css'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import { resetPasswordSaga, checkTemporaryTokenSaga } from './saga'
import { resetPassword, checkTemporaryToken } from '../App/actions'
import TGD2LogoImg from '../../images/SimuTGD2Logo.svg'
import SIMU02LogoImg from '../../images/SIMU02Logo.svg'
import InputValidateIcon from '../../images/InputValidateIcon.svg'
import ThumbUpIcon from '../../images/ThumbUpIcon.svg'
import { ThumbUp, InputIcon, ContentContainer, SIMU02Logo, HeaderContainer, TGD2Logo, BottomContainer, LayoutContainer, Container, TextContainer, InputContainer, Text, Input, Button, ButtonContainer, SuccessText, SuccessDetailText } from './styledComponents'

const ResetPassword = (props) => {
  const error = useSelector((state) => state.toJS().global.error)
  const loading = useSelector((state) => state.toJS().global.loading)
  const [isDone, setIsDone] = useState(false)
  const location = useLocation()
  const [password, setPassword] = useState('')
  const [activateButton, setActivateButton] = useState(false)
  const [repeatPassword, setRepeatPassword] = useState('')
  const token = location.pathname.split('/')[2]
  const studentId = location.pathname.split('/')[3]
  const dispatch = useDispatch()

  const userAgent = navigator.userAgent
  let browser
  let isMobile = false

  console.log(userAgent)

  if (userAgent.indexOf('Chrome') > -1) {
    browser = 'Chrome'
  } else if (userAgent.indexOf('Firefox') > -1) {
    browser = 'Firefox'
  } else if (userAgent.indexOf('Safari') > -1) {
    browser = 'Safari'
  } else if (userAgent.indexOf('Opera') > -1) {
    browser = 'Opera'
  }

  if (userAgent.indexOf('Mobile') > -1) {
    isMobile = true
  }

  console.log(browser)
  console.log(isMobile)

  useEffect(() => {
    dispatch(checkTemporaryToken({ token, studentId }))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (password && repeatPassword && password === repeatPassword) {
      setActivateButton(true)
    } else {
      setActivateButton(false)
    }
  }, [password, repeatPassword])

  const handleChange  = (e, type) => {
    if (type === 'password') {
      setPassword(e.target.value)
    } else {
      setRepeatPassword(e.target.value)
    }
  }

  const handleClick = () => {
    dispatch(resetPassword(studentId, token, password))
    setTimeout(() => {
      if (!loading && !error) {
        setIsDone(true)
      }
    }, 100)
  }

  console.log('==>', browser === 'Chrome' && isMobile)

  return (
    <LayoutContainer>
      <Container>
        <HeaderContainer>
          <SIMU02Logo src={SIMU02LogoImg} />
        </HeaderContainer>
        <ThumbUp style={{ display: isDone ? 'unset' : 'none' }} src={ThumbUpIcon}/>
        <ContentContainer>
          <TextContainer>
            <Text>
              {
              error ?
              'Le lien a expiré' :
              isDone ?
              <SuccessText>
                Votre mot de passe a été réinitialisé
                <SuccessDetailText>
                  Un mail de confirmation vous a été envoyé avec votre nouveau mot de passe.
                </SuccessDetailText>
              </SuccessText> :
                'Saisissez votre nouveau mot de passe :'
              }
            </Text>
          </TextContainer>
        {error || isDone ? null :
        <>
          <InputContainer>
            <Input onChange={(e) => handleChange(e, 'password')} value={password} placeholder={'Nouveau mot de passe'} />
            <InputIcon style={{display: activateButton ? 'unset' : 'none'}} src={InputValidateIcon} />
          </InputContainer>
          <InputContainer>
            <Input onChange={(e) => handleChange(e, 'confirmPassword')} value={repeatPassword} placeholder={'Répéter le mot de passe'} />
            <InputIcon style={{display: activateButton ? 'unset' : 'none'}} src={InputValidateIcon} />
          </InputContainer>
          <ButtonContainer>
            <Button
              disabled={!activateButton}
              data-type='premium'
              style={{
                backgroundColor: activateButton ? 'rgba(255, 242, 0, 1)' : 'unset',
                color: activateButton ? 'black' : 'white',
                fontWeight: activateButton ? 'bold' : 'unset',
                cursor: activateButton ? 'pointer' : 'unset',
                border: activateButton ? 'unset' : '1px solid white'
              }}
              onClick={() => handleClick()}>
              VALIDER
            </Button>
          </ButtonContainer>
        </>
        }
        </ContentContainer>
        <BottomContainer>
          <TGD2Logo src={TGD2LogoImg} />
        </BottomContainer>
      </Container>
    </LayoutContainer>
  )
}

const withConnect = connect(null, null)
const withCheckToken = injectSaga({ key: 'checkTemporaryToken', saga: checkTemporaryTokenSaga })
const withSaga = injectSaga({ key: 'resetPassword', saga: resetPasswordSaga })

const arg = compose(withConnect, withCheckToken, withSaga)(ResetPassword)

export default arg
