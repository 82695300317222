import styled from 'styled-components'
import backgroundImage from '../../images/UIResetPasswordBackground.png'

export const SuccessText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 400;
`

export const SuccessDetailText = styled.p`
  font-size: 14px;
  margin-top: 20px;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  width: 150%;
`

export const Button = styled.button`
  width: 140px;
  height: 48px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  color:#868383;
  border: 2px solid white;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
`

export const LayoutContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundImage});
  background-position: center;
  background-size: cover;
  position: fixed;
`

export const BottomContainer = styled.div`
  border-top: 1px solid black;
  box-sizing: content-box;
  height: 6.875rem;
  width: 100%;
  bottom: 0px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HeaderContainer = styled.div`
  width: 100%;
  margin-top: 4.5rem;
  margin-bottom: 7.563rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width:420px){
    margin-top: 3rem;
    margin-bottom: 2.5rem;
  }
`

export const ContentContainer = styled.div`
  width: 20rem;
  height: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media(max-width:420px){
    width: 70%;
  }
`

export const TGD2Logo = styled.img`
  height: 46.3px;
`

export const ThumbUp = styled.img`
  height: 102px;
@media(max-width:420px){
  height: 62px;
  margin-bottom: 1.5rem;
}
`

export const SIMU02Logo = styled.img`
  height: 140px;
  width: 140px;
  @media(max-width:820px){
    height: 100px;
  }
  @media(max-width:420px){
    height: 100px;
  }
`


export const Container = styled.div`
  position: relative;
  width: 26.45%;
  height: 100vh;
  background-color: #3B1F4A;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1;
  @media(max-width:820px){
    width: 80vw;
    height: calc(100vh - 3rem);
  }
  @media(max-width:420px){
    width: 100%;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  height: 20%;
  font-size: 24px;
  font-family: Poppins;
  line-height: 1.4;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 29px;
`

export const Text = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media(max-width:420px){
    font-size: 18px;
  }
`

export const InputContainer = styled.div`
  width: 100%;
  heigth: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const Input = styled.input`
  height: 52px;
  display: flex;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: black;
  width: 100%;
  padding-left: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  outline: none;
  &:focus {
    border-spacing: 5px;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: gray;
  }
  :-ms-input-placeholder {
     color: black;
  }
  @media(max-width:420px){
    width: 100%;
    font-size: 16px;
  }
`

export const InputIcon = styled.img`
  height: 16px;
  position: absolute;
  right: 10px;
  top: 20px;
`