import React from 'react'
import styled from 'styled-components'
import '../../fonts/fonts.css'
import SimuPlayerArchive from '../SimuPlayerArchive/index'
import SideBar from '../SideBar/index'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import MainTitle from '../../components/MainTitle'
import PopUpdate from '../PopUpdate/index'
import UiArchiveActive from '../../images/Archive.svg'

const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
`
const ContentLayout = styled.div`
  display: inline;
  flex-direction: row;
  height: 100%;
  padding-top: 100px;
`

const SimuPlayersPage = () => {
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  
  return (
    <Bg>
      <SideBar isAdmin={isAdmin} />
      <MainLayout>
        <MainTitle text='Élèves Clôturés' icon={UiArchiveActive} />
        <ContentLayout>
          <PopUpdate />
          <SimuPlayerArchive />
        </ContentLayout>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null)
const arg = compose(withConnect)(SimuPlayersPage)

export default arg
