import styled from 'styled-components'
import Select from 'react-select'
import { Link } from 'react-router-dom'


export const Button = styled.button`
  width: 5.4rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #868383;
  border-radius: 10px;
  background-color: #eff0f6;
  border: unset;
`

export const Success = styled.div`
  position: absolute;
  z-index: 10;
  top: 10rem;
  left: 19rem;
  height: 10rem;
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #fffcd3;
  font-family: Poppins;
  @media(max-width:1536px){
    top: 10rem;
    left: 14.5rem;
    height: 10rem;
    width: 13rem;
  }
`
export const TextSuccess = styled.div`
  margin-top: 1rem;
  font-size: 1.1rem;
`
export const ImgSuccess = styled.img`
  margin-top: 1rem;
  height: 4rem;
`

export const SelectorContainer = styled.div`
  flex-direction: column;
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  height: 7rem;
  align-items: space-between;
`

export const SelectorScaleEval = styled(Select)`
.react-select__control {
  border-radius: 10px;
  width: 18rem;
  color:black;
  font-family:Poppins;
  font-size:13px;
  outline: none;
  border: 1px solid #B3ABBC;
  height: 2.75rem;
  cursor: pointer;
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  color: black;
}
  & .react-select__control--is-focused {box-shadow: 0px 0px 3px 3px #00C4F3;}
`

export const ArrowDown = styled.img`
  width: 0.8rem;
  position: absolute;
  margin-top: 15px;
  margin-right: 12px;
  z-index: 0;
  pointer-events: none;
`

export const SelectorScaleEvalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const SelectorText = styled.p`
  line-height: 23.27px;
`

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const InfoContainer = styled.div`
  width:16rem;
  background-color: #FFFCD3;
  display: flex;
  margin-top: 5rem;
  border-radius: 10px;
  align-items: center;
  padding: 0.5rem;
`

export const FilterText = styled.div`
  color: black;
  font-family: Poppins;
  font-size: 1rem;
  line-height: 23.27px;
  display: flex;
  padding: 1rem;  
`

export const MainContainer = styled.div`
  display:flex;
  margin-top: 8rem;
  width:93%;
  height:45rem;
  align-items: flex-start;
  justify-content: center;
`

export const ScalesGridText = styled.span`
  text-align: end;
`

export const ScalesContainer = styled.div`
  width:40rem;
  height:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-right:3rem;
`

export const ScaleColumn = styled.div`
  width: 7rem;
  background-color: #CCEAFF;
  height:100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-family:Poppins;
  border-radius:10px;
  font-style: normal;
  font-weight:500;
  font-size: 1.25rem;
`

export const ScalesGridContainer = styled.div`
  padding-top:2rem;
  width:100%;
  height:100%;
  border: 2px solid white;
  display: flex;
  justify-content: space-between;
`

export const ScalesGridBottomContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`

export const ScalesRightOptionsContainer = styled.div`
  height:100%;
  width:24.5rem;
`

export const ScalesInfoContainer = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  text-align:center;
  font-size:1.1rem;
  font-weight:400;
  style:normal;
  width:100%;
  margin:auto;
  border-bottom: 1px solid #979797;
`

export const ScalesVersion = styled(Link)`
  height:41px;
  cursor:pointer;
  text-decoration:none;
  font-family:Poppins;
  flex: 1;
  color:#166DD8;
  &{
    border-bottom:3px solid #166DD8;
  }
`
export const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
`

export const MainTitleContainer = styled.div`
  width:80%;
  margin-left:26.5rem;
  @media(max-width:1536px){
    z-index:2;
    width:77.5%;
    margin-left:15.6rem;
    background-color:#ffffff;
  }
`

export const Input = styled.input`
  width: 3.2rem;
  font-family: Poppins;
  font-style:normal;
  font-size: 1rem;
  padding-left:1rem;
  padding-right:1rem;
  padding-top:0.5rem;
  padding-bottom:0.5rem;
  border-radius:0.5rem;
  border:1px solid #B3ABBC;
  background-color:white;
  text-align:center;
  margin-bottom:1rem;
`