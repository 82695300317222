import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import '../../fonts/fonts.css'
import SideBar from '../SideBar'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import { simuPlayerEditData, deleteSimuPlayerSaga } from './saga'
import { simuPlayerEdit, deleteSimuPlayer, hideModal } from '../App/actions'
import MainTitle from '../../components/MainTitle'
import pen from '../../images/pen.svg'
import UiDanger from '../../images/UiWarn.svg'
import SuccessIcon from '../../images/successface.svg'
import ModalConfirm from '../../components/ModalConfirm'
import config from '../../config/configurator'
import { SelectorLicenceContainer, MainContainer, DashboardContainer, LicenceSelector, ButtonContainer, AgencySelector, Success, ImgSuccess, TextSuccess, Bg, MainLayout, InfoContainer, Info, Stats, FormContainer, BoxContainer, Name, Input, InputDisplayer, SelectorAgencyContainer, FeedBack, DeleteButton, DeleteInput, Button, ErrorContainer } from './styledComponents'
import Dashboard from '../../components/Dashboard'

const PlayerEdit = (props) => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()

  const players = useSelector((state) => state.toJS().global.simuPlayers)
  const error = useSelector((state) => state.toJS().global.error)
  const loading = useSelector((state) => state.toJS().global.loading)
  const token = useSelector((state) => state.toJS().global.agencytoken)
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const successStore = useSelector((state) => state.toJS().global.success)

  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const player = players ? players.find(player => player.id === id || player._id === id) : ''

  const [success, setSuccess] = useState()
  const [password, setPassword] = useState('')
  const [repPassword, setRepPassword] = useState('')
  const [wrongRepeat, setWrongRepeat] = useState('')
  const [playerId, setPlayerId] = useState(id)
  const [playerAgency, setPlayerAgency] = useState(player.agencyId)
  const [firstname, setFirstname] = useState(player.firstname)
  const [lastname, setLastname] = useState(player.lastname)
  const [email, setEmail] = useState(player.email)
  const [color, setColor] = useState([])
  const [borderColor, setBorderColor] = useState()
  const [modalName, setModalName] = useState()
  const [modalText, setModalText] = useState()
  const [borderBottom, setBorderBottom] = useState([])
  const [verif, setVerif] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [warn, setWarn] = useState({ lastname: false, firstname: false, mail: false, psw: false, repPsw: false, error: false })
  const [showModal, setShowModal] = useState(false)
  const [errorCode, setErrorCode] = useState(0)
  const [playerLicence, setPlayerLicence] = useState(player.trainingType || '')

  useEffect(() => {
    displayModal(successStore)
    errorCheck(error)
    // eslint-disable-next-line
  }, [player._id, loading, successStore, error])

  const errorCheck = (err) => {
    if (err) {
      switch (error) {
        case "Failed to fetch":
          setSuccess(false); setWarn({ error: true }); setModalText('Impossible d\'accéder au serveur'); setErrorCode(error)
          break
        default:
          setSuccess(false); setWarn({ error: true }); setModalText('Erreur'); setErrorCode(error)
      }
    }
  }

  const displayModal = (show) => {
    if(show){
      setSuccess(true)
      setTimeout(() => {
        dispatch(hideModal())
        setSuccess(false)
      }, 2000);
    }
  }


  const handleClick = () => {
    dispatch(simuPlayerEdit({ id, firstname: firstname, lastname: lastname, email: email, password: password, agencyid: playerAgency, token: token, type: location.state, trainingType: playerLicence }))
    reset()
  }

  const reset = () => {
    setPassword('')
    setRepPassword('')
    setVerif(false)
  }

  const verification = () => {
    if (lastname && firstname && email && (password === repPassword)) {
      if(password && repPassword !== null){
        if (!emailCheck()) {
          setVerif(true)
        }
    }
    } else {
      setVerif(false)
    }
  }

  const onKeyPress = event => {
    if (event.key === 'Enter') {
      if (verif) { handleClick() }
    }
  }

  // function inputHandler (e, value) {
  //   setState({ [value]: e.target.value })
  // }

  /* function handleChange (e) {
    history.push(`/simuplayers/edit/${e.target.value}`)
    const newPlayer = players.find(player => player.id.toString() === e.target.value)
    setPlayerId(e.target.value)
    setFirstname(newPlayer.firstname)
    setLastname(newPlayer.lastname)
    setEmail(newPlayer.email)
    if (isAdmin) {
      setPlayerAgency(newPlayer.agencyId)
    }
  }
*/
  // switch color in the infoContainer to know where we are
  const onClickColor = (e) => {
    setColor([
      e === 0 ? '#166DD8' : 'black',
      e === 1 ? '#166DD8' : 'black',
      e === 2 ? '#166DD8' : 'black'
    ])
    setBorderBottom([
      e === 0 ? '3px solid #166DD8' : 'black',
      e === 1 ? '3px solid #166DD8' : 'black',
      e === 2 ? '3px solid #166DD8' : 'black'
    ])
  }

  // function displaySelector () {
  //  if (players) {
  //    return (
  //      <SimuPlayerSelector style={{ marginTop: '4rem', outline: 'none' }} onChange={(e) => { handleChange(e) }} value={id}>
  //        {players.map((pl, i = 0) => {
  //          if (pl.foreignKey === 'fkeditor') {
  //            return null
  //          }
  //          return <Opt key={i} value={pl.id}>{pl.firstname + ' ' + pl.lastname}</Opt>
  //        })}
  //      </SimuPlayerSelector>
  //    )
  //  }
  // }

  function handleChangeAgency (e) {
    setPlayerAgency(e.value)
    setVerif(true)
  }

  function displayAgencySelector () {
    if (agencies && player) {
      const agencyValues = []
      agencies.forEach((ag) => agencyValues.push({ value: ag.agencyId, label: ag.agencyName }))
      let currentAgencyIndex = agencyValues.indexOf(agencyValues.find((ag) => ag.value === player.agencyId))

      return (
        <AgencySelector
          classNamePrefix='react-select'
          multiple={false}
          isSearchable={false}
          defaultValue={agencyValues[currentAgencyIndex]}
          placeholder={'Aucune agence'}
          onChange={(e) => {handleChangeAgency(e)}}
          options={agencyValues}>
        </AgencySelector>
      )
    } else {
      return null
    }
  }

  function handleChangeLicence (e) {
    setPlayerLicence(e.value)
    setVerif(true)
  }

  const licences = [
    {value: 'B', label: 'B'},
    {value: 'BEA', label: 'BEA'},
    {value: 'AAC', label: 'AAC'},
    {value: 'BEA AAC', label: 'BEA AAC'}
  ]

  const DisplayLicenceSelector = () => {
    let currentLicenceIndex = licences.indexOf(licences.find((li) => li.value === player.trainingType))

      return (
        <LicenceSelector
          classNamePrefix='react-select'
          multiple={false}
          isSearchable={false}
          defaultValue={licences[currentLicenceIndex]}
          onChange={(e) => {handleChangeLicence(e)}}
          options={licences}>
        </LicenceSelector>
      )
  }

  const emailCheck = () => {
    if (!email) {
      return
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null) {
      setBorderColor('#FF0000')
      setWarn({ mail: true })
      setEmailError('Mail non valide')
      return true
    }
    setBorderColor('#B3ABBC')
    setWarn({ mail: false })
    return false
  }

  function renderSuccess () {
    return (
      <Success>
        <ImgSuccess src={SuccessIcon} />
        <TextSuccess>L'élève à bien été modifié !</TextSuccess>
      </Success>
    )
  }

  function confirmDelete (id, action, firstname) {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
  }

  const deletePlayer = (id) => { // FIXME: set timeout ???
    dispatch(deleteSimuPlayer(token, id, playerAgency))
    setTimeout(() => {
      history.push('/simuplayers')
    }, 100)
  }

  const handleChangePassword = useCallback ((event) => {
    const pass = event.target.value
    setPassword(pass)
  }, [])

  const handleChangeRepPassword = useCallback ((event) => {
    const repPass = event.target.value
    setRepPassword(repPass)
  }, [])

  const checkPassword = () => {
    if (repPassword && password && (repPassword !== password)) {
      setWrongRepeat(true)
      setColor('#868383')
    } else {
      setWrongRepeat(false)
    }
  }

  if (!player.firstname) {
    return <div>LOADING</div>
  }

  return (
    <Bg>
      <SideBar />
      <MainLayout>
        {success
          ? renderSuccess()
          : <div />}
        <MainTitle text='Le compte élève' icon={pen} />
        <MainContainer>
        <ModalConfirm 
          show={showModal}
          confirmText='Supprimer'
          text={modalText} 
          name={modalName} 
          confirm={() => deletePlayer(playerId)}
          cancel={() => setShowModal(false)}
        />
        {/* <SelectorStudentContainer>
          <ArrowStudent src={ArrowDown} />
          {displaySelector()}
        </SelectorStudentContainer> */}
        <DashboardContainer>
          <Dashboard height={'48px'} type='edit' data={{firstName: firstname, lastName: lastname}}/>
        </DashboardContainer>
        <InfoContainer>
        <Info
            style={{
              color: color[0],
              borderBottom: borderBottom[0]
            }}
            onClick={(e) => onClickColor(0)}
          >
          Informations
        </Info>
        <Stats
            to={{ pathname: `/simuplayers/statsb2vm/${playerId}`, query: { id: playerId } }}
            style={{
              color: color[1],
              borderBottom: borderBottom[1]
            }}
            onClick={(e) => onClickColor(1)}
          >
            Statistiques
          </Stats>
          {
          /*
          <Parcours
            style={{
              color: color[1],
              borderBottom: borderBottom[1]
            }}
            onClick={(e) => onClickColor(1)}
          >
            Parcours
          </Parcours>
          */
          }
        </InfoContainer>
        <FormContainer>
          {isAdmin
            ? <BoxContainer>
              <Name>Agence</Name>
              <SelectorAgencyContainer>
                {displayAgencySelector()}
              </SelectorAgencyContainer>
              </BoxContainer>
            : <div />}
          <BoxContainer>
            <Name>Nom</Name>
            <InputDisplayer>
              <Input
                readOnly={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                disabled={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                autocomplete='off'
                value={lastname}
                style={{ backgroundColor: config.title === 'ECF' || 'PP ADMIN' ? '#F5F5F5' : 'white' }}
                onKeyUp={() => verification()}
                onChange={(e) => { setLastname(e.target.value) }}
              />
            </InputDisplayer>
            <FeedBack style={{ opacity: warn.lastname ? '1' : '0' }}>Tous les champs ne sont pas valides</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>Prénom</Name>
            <InputDisplayer>
              <Input
                readOnly={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                disabled={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                autocomplete='off'
                style={{ backgroundColor: config.title === 'ECF' || 'PP ADMIN' ? '#F5F5F5' : 'white' }}
                onKeyUp={() => verification()}
                value={firstname}
                onChange={(e) => { setFirstname(e.target.value) }}
              />
            </InputDisplayer>
            <FeedBack style={{ opacity: errorCode === 409 ? '0' : '0' }}>{firstname + ' ' + lastname + 'existe déjà'}</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name>Email</Name>
            <InputDisplayer>
              <Input
                readOnly={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                disabled={config.title === 'ECF' || config.title === 'PP ADMIN' ? true : false}
                autocomplete='off'
                style={{ borderColor: borderColor, backgroundColor: config.title === 'ECF' || 'PP ADMIN' ? '#F5F5F5' : 'white' }}
                onKeyUp={() => verification()}
                value={email}
                onChange={(e) => { setEmail(e.target.value); emailCheck() }}
              />
            </InputDisplayer>
            <FeedBack style={{ opacity: warn.mail ? '1' : '0' }}>{emailError}</FeedBack>
          </BoxContainer>
          <BoxContainer>
            <Name style={{marginTop:'0.7rem'}}>Mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyUp={() => {verification(); checkPassword()}}
                onKeyPress={onKeyPress}
                value={password}
                type='text'
                onChange={(e) => {handleChangePassword(e)}}
              />
              {wrongRepeat ? (<DeleteInput style={{ bottom: '37.2%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </InputDisplayer>
          </BoxContainer>
          <BoxContainer>
            <Name>Répeter le mot de passe</Name>
            <InputDisplayer>
              <Input
                autocomplete='off'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onKeyUp={() => {verification(); checkPassword()}}
                onKeyPress={onKeyPress}
                value={repPassword}
                type='text'
                onChange={(e) => {handleChangeRepPassword(e)}}
              />
              {wrongRepeat ? (<DeleteInput style={{ bottom: '17.7%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </InputDisplayer>
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>
              Les mots de passes ne correspondent pas
            </FeedBack>
          </BoxContainer>
            <BoxContainer>
              <Name>Permis</Name>
              <SelectorLicenceContainer>
                {DisplayLicenceSelector()}
              </SelectorLicenceContainer>
              </BoxContainer>
          <ButtonContainer>
            <DeleteButton onClick={() => confirmDelete(id, 'Attention, vous allez supprimer définitivement le compte de ', (lastname + ' ' + firstname))}>Supprimer</DeleteButton>
            <Button disabled={!verif} data-type='premium' style={{ backgroundColor: verif ? '#00C4F3' : '#EFF0F6', color: verif ? 'black' : '#868383', cursor: verif ? 'pointer' : 'unset' }} onClick={() => handleClick()}>
              Enregistrer les modifications
            </Button>
          </ButtonContainer>
        </FormContainer>
        <ErrorContainer>
          <FeedBack style={{ opacity: warn.error ? '1' : '0' }}>{modalText}</FeedBack>
        </ErrorContainer>
        </MainContainer>
      </MainLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'simuPlayerEdit', saga: simuPlayerEditData })
const withDeleteSaga = injectSaga({ key: 'deleteSimuPlayer', saga: deleteSimuPlayerSaga })

const arg = compose(withSaga, withDeleteSaga, withConnect)(PlayerEdit)

export default arg
