
import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { getTokenSimuSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { getTokenSimu, hideModal } from '../App/actions'
import '../../fonts/fonts.css'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import AddStudent from '../../images/AddStudent.svg'
import ModalConfirm from '../../components/ModalConfirm/index'
import SuccessIcon from '../../images/successface.svg'
import UiDanger from '../../images/UiWarn.svg'
import { FormContainer, SelectorLicenceContainer, LicenceSelector, BoxTitle, BoxContainer, Bg, ImgSuccess, Success, Input, DeleteInput, FeedBack, MainLayout, TextSuccess, Container, Button } from './styledComponents'

const PlayerCreate = () => {
  const dispatch = useDispatch()

  const agencyToken = useSelector((state) => state.toJS().global.agencytoken)
  const error = useSelector((state) => state.toJS().global.error)
  const successStore = useSelector((state) => state.toJS().global.success)

  const [lastName, setLastName] = useState('')
  const [firstName, setFirstName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [repPassword, setRepPassword] = useState('')
  const [wrongRepeat, setWrongRepeat] = useState()
  const [show, setShow] = useState(false)
  const [modalText, setModalText] = useState(false)
  const [verif, setVerif] = useState(false)
  const [warn, setWarn] = useState({ mail: false, psw: false, fName: false, error: false })
  const [borderColor, setBorderColor] = useState({ mail: '', psw: '' })
  const [success, setSuccess] = useState(false)
  const [playerLicence, setPlayerLicence] = useState('')

  useEffect(() => {
    displayModal(successStore)
    errorCheck(error)
    verification()
    // eslint-disable-next-line
  }, [error, successStore,playerLicence])

  const displayModal = (show) => {
    if(show){
      setSuccess(true)
      setTimeout(() => {
        dispatch(hideModal())
        setSuccess(false)
        setVerif(false)
      }, 2000);
    }
  }

  const errorCheck = (err) => {
    if (err) {
      if (err !== undefined && err.response !== undefined) {
        switch (err.response.status) {
          case 401:
            setShow(true); setSuccess(false); setWarn({ err: true }); setModalText('Vous n\'êtes pas identifié, veuillez vous déconnecter puis vous reconnecter')
            break
          case 409:
            setShow(true); setSuccess(false); setWarn({ err: true }); setModalText(`L'élève existe déjà.`)
            break
          default:
            setShow(true); setSuccess(false); setWarn({ fName: true }); setModalText('Erreur système')
            break
        }
      } else {
        setShow(true); setSuccess(false); setWarn({ err: true }); setModalText('Impossible d\'accéder au serveur')
      }
    }
  }

  const licences = [
    {value: 'B', label: 'B'},
    {value: 'BEA', label: 'BEA'},
    {value: 'AAC', label: 'AAC'},
    {value: 'BEA AAC', label: 'BEA AAC'}
]

  const DisplayLicenceSelector = () => {
      return (
        <LicenceSelector
          classNamePrefix='react-select'
          multiple={false}
          isSearchable={false}
          value={playerLicence ? licences.value : null}
          placeholder={'Sélectionnez un permis ...'}
          onChange={(e) => {setPlayerLicence(e.value); verification()}}
          options={licences}>
        </LicenceSelector>
      )
  }

  function verification () {
    if (lastName && firstName && email && password && repPassword && playerLicence && (password === repPassword)) {
      if (!emailCheck()) {
        setVerif(true)
      }
    } else {
      setVerif(false)
    }
  }

  const handleChangeMail = useCallback ((event) => {
    const mail = event.target.value
    setEmail(mail)
  }, [])

  const emailCheck = () => {
    if (!email) {
      setBorderColor('#B3ABBC')
      setWarn({ mail: false })
      return
    }
    const expReg = /\S+@\S+\.\S+/
    const result = email.match(expReg)
    if (result == null && email) {
      setBorderColor('#FF0000')
      setWarn({ mail: true })
      return true
    } else {
      setBorderColor('#B3ABBC')
      setWarn({ mail: false })
      return false
    }
  }

  const handleChangePassword = useCallback ((event) => {
    const pass = event.target.value
    setPassword(pass)
  }, [])

  const handleChangeRepPassword = useCallback ((event) => {
    const repPass = event.target.value
    setRepPassword(repPass)
  }, [])


  const checkPassword = () => {
    if (repPassword && password && (repPassword !== password)) {
      setWrongRepeat(true)
    } else {
      setWrongRepeat(false)
    }
  }

  const clickHandlerAccount = () => {
    if (!verif) { return }
    dispatch(getTokenSimu({
      email: email,
      password: password,
      foreignKey: email,
      firstname: firstName,
      lastname: lastName,
      status: 1,
      trainingType: playerLicence,
      access: 'premium',
      token: agencyToken}))
    reset()
  }

  const reset = () => {
    setVerif(true)
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
    setRepPassword('')
    setPlayerLicence('')
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      clickHandlerAccount()
    }
  }

  return (
    <Bg>
      <SideBar />
      <ModalConfirm
          show={show}
          text={'Attention'.toUpperCase()}
          confirm={() => setShow(false)}
          name={modalText}
          confirmText='OK'
          noCancel
        />
      <MainLayout>
        {success
         ? <Success>
             <ImgSuccess src={SuccessIcon} />
             <TextSuccess>L'élève à bien été créé !</TextSuccess>
           </Success>
         : <div />}
        <MainTitle text='Créer un élève' icon={AddStudent} />
        <Container>
          <FormContainer>
            <BoxContainer>
              <BoxTitle>Nom</BoxTitle>
              <Input
                value={lastName}
                onChange={(e) => { setLastName(e.target.value) }}
                onKeyPress={() => onKeyPress}
                onKeyUp={() => verification()}
              />
            </BoxContainer>
            <BoxContainer>
              <BoxTitle>Prénom</BoxTitle>
              <Input
                value={firstName}
                onChange={(e) => { setFirstName(e.target.value) }}
                onKeyPress={(e) => onKeyPress(e)}
                onKeyUp={() => verification()}
              />
            </BoxContainer>
            <BoxContainer>
              <BoxTitle>Email</BoxTitle>
              <Input
                value={email}
                style={{ borderColor: borderColor }}
                onChange={(e) => { handleChangeMail(e) }}
                onKeyPress={(e) => onKeyPress(e)}
                onKeyUp={() => {verification(); emailCheck()}}
              />
              <FeedBack style={{ opacity: warn.mail ? '1' : '0' }}>Email non valide</FeedBack>
            </BoxContainer>
            <BoxContainer>
              <BoxTitle>Mot de passe</BoxTitle>
              <Input
                value={password}
                type='text'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onChange={(e) => handleChangePassword(e)}
                onKeyPress={(e) => onKeyPress(e)}
                onKeyUp={() => {checkPassword(); verification()}}
              />
              {wrongRepeat ? (<DeleteInput style={{ bottom: '32.0%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            </BoxContainer>
            <BoxContainer>
              <BoxTitle>Répéter le mot de passe</BoxTitle>
              <Input
                value={repPassword}
                type='text'
                style={{ borderColor: wrongRepeat ? 'red' : '#B3ABBC' }}
                onChange={(e) => handleChangeRepPassword(e)}
                onKeyPress={(e) => onKeyPress(e)}
                onKeyUp={() => {checkPassword(); verification()}}
              />
              {wrongRepeat ? (<DeleteInput style={{ bottom: '32%', right: '3%', height: '1rem' }} src={UiDanger} />) : null}
            <FeedBack style={{ opacity: wrongRepeat ? '1' : '0' }}>Les mots de passes ne correspondent pas</FeedBack>
            </BoxContainer>
            <BoxContainer>
              <BoxTitle>Choisir un permis</BoxTitle>
              <SelectorLicenceContainer>
                {DisplayLicenceSelector()}
              </SelectorLicenceContainer>
            </BoxContainer>
            <Button data-type='premium' disabled={!verif} style={{ backgroundColor: verif ? '#00C4F3' : '#EFF0F6', color: verif ? 'black' : '#868383' }} onClick={() => clickHandlerAccount()}>Créer l'élève</Button>
          </FormContainer>
        </Container>
      </MainLayout>
    </Bg>
  )
}

const withSaga = injectSaga({ key: 'SimuPlayerCreate', saga: getTokenSimuSaga })
const arg = compose(withSaga)(PlayerCreate)

export default arg
