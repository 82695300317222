import styled from 'styled-components'
import UiBoy from '../../images/boy.svg'

// General composent
export const DashboardContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 24px;
`

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-x:hidden;
`

export const MainLayout = styled.div`
  float: right;
  display: flex;
  width: 80%;
  background-color: white;
  margin-left: 18%;
  @media(max-width:1536px){
      height: 110vh;
      margin-left: 17%;
    }
    @media(max-width:1440px){
      height: 135vh;
    }
    @media(max-width:1366px){
      height: 125vh;
    }
`

// Content part


export const ContentContainer = styled.div`

  padding-top: 100px;
  margin: auto;
  @media(max-width:1536px){
    margin-top:3.45rem;
  }
`

// Scrolling menu components
export const SimuPlayerSelector = styled.select`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #B3ABBC;
  outline: none;
  height: 2.37rem;
  width: 100%;
  padding-left: 2.25rem;
  margin-bottom: 2rem;
  &:focus {box-shadow: 0px 0px 3px 3px #00C4F3;}
  background-image: url(${UiBoy});
  background-size: 1.1rem;
  background-position: top 50% left 1rem;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 8px;
`

export const SelectorContainer = styled.div`
  display: flex;
  justify-content: center;
`

export const ArrowDown = styled.img`
width: 0.8rem;
position: absolute;
margin-top: 78px;
margin-left: 12.5%;
z-index: 10;
`

export const Opt = styled.option`
  padding-left: 3rem;
`

export const BlocTitle = styled.div`
  width:16.3rem;
  height:1.75rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  width:6rem;
  color: white;
  font-family:Poppins;
  width: auto;
  text-align:center;
  margin-top: 1rem; 
  margin-bottom: 1rem;
`

// Main rates elements
export const StatsMainBloc = styled.div`
  display: flex;
  height: 10rem;
  justify-content: space-between
`

export const StatsBloc = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
  background:${props => props.color1};
  background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2});
  margin-left:0rem;
  align-items:center;
  border:1px solid #979797;
  border-radius: 10px;
`

// Details rates

export const StatsItemContainer = styled.div`
  height: 3.5rem; 
  display: flex; 
  align-items: center; 
  justify-content: space-between;
`

export const StatsContainer = styled.div`
  height: 35rem;
  margin-top:1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const StatsRow = styled.div`
  height:100%;
  width : 80%;
  display: flex;
  align-items:center;
  border: 1px solid #979797;
  border-radius: 10px;
`

export const Text = styled.div`
position:static;
width:23.4rem;
height:1.5rem;
left:1rem;
line-height:1.5rem;
font-size: 1.125rem;
font-family: Poppins;
font-weight: 500;
font-style:normal;
padding-left:1rem;
text-align: left;
align-items:center;
display:flex;
`

export const Contour = styled.span`
  width: 5rem;
  height:3.5rem;
  display: flex;
  border-radius: 10px;
  background-color: #EBEFF7;
  color: #024179;
  box-sizing: border-box;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
`

export const StatsGrey = styled.span`
  color: #024179;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 300;
  padding-left: 0.1rem;
  line-height: 2.1rem;
  font-family: Poppins;
`

export const Score = styled.div`
  color: #024179;
  font-style: normal;
  font-family: Poppins;
  font-weight: 500;
  font-size: 2.1rem;
  padding-top:0rem;
  font-weight: 600;
`

export const ContourNote = styled.div`
  display: flex;
  align-items: flex-end;
`

export const ScoreEval = styled.div`
color: white;
font-style: normal;
font-size: 4.68rem;
line-height: 4rem; 
font-family: Poppins;
font-weight:bold;
`

export const ScoreNote = styled.div`
  color: white;
  font-style: normal;
  font-size: 1.2rem;
  font-family: Poppins;
  font-weight: 300;
  margin-left: 0.8rem;
`

// Printer components
export const PrintContainer = styled.div`
  position: relative;
  display: flex;
  height:3rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Print = styled.button`
position:absolute;
display: flex;
justify-content: space-around; 
align-items: center;  
width:8.1rem;
height: 100%;
right: 0; 
font-family: Poppins;
font-size: 1rem;
font-weight: 500;
font-style:normal;
line-height:1rem;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
border:1px solid #979797;
border-radius: 0.5rem;
cursor:pointer;
box-shadow:0px 2px 7px rgba(0,0,0,0.14);
`
