import styled from 'styled-components'

export const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 114vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.5);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Logo = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 8px;
`

export const Container = styled.div`
  background-color: white;
  width: 16.5%;
  height: 18.33%;
  font-family: Poppins;
  color: black;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TextContent = styled.div`
  width: 100%;
  margin-left: 8%;
  margin-top: 8%;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
`

export const Title = styled.span`
`

export const FirstWord = styled.span`
  color: red;
`

export const Name = styled.div`
  margin-top: 12px;
  width: 60%;
  font-size: 1rem;
  font-weight: 300;
  text-align: left;
  margin-left: 8%;
  align-items: center;
  display: flex;
  `
export const Icon = styled.img`
  height: 80%;
  margin-top: 0;
  margin-right: 4%;
  margin-bottom: 0%;
`

export const ButtonCancel = styled.button`
  height: 38px;
  width: 88px;
  background-color: white;
  border: solid 0.1px grey;
  border-radius: 0.5rem;
  color: #868383;
  font-family: Poppins;
  font-weight: 500;
  font-size: 0.875rem;
  margin-right: 1rem;
  &:hover {
    cursor: pointer;
  }
`

export const ButtonAction = styled.button`
  height: 38px;
  width: 108px;
  background-color: #61D7F3;
  color: black;
  font-family: Poppins;
  font-size: 0.875rem;
  font-weight 500;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.1rem 0.2rem rgba(0, 0, 0, 0.14);
  border: none;

  &:hover {
    cursor: pointer;
  }
`

export const ContentBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 32px;
  padding-bottom: 32px;
  
`
export const ContentText = styled.div`
  height: 50%;
`
