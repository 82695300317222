import styled from 'styled-components'

export const Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  overflow-x:hidden;
`
export const ButtonContainer = styled.div`
width:100%;
height:100%;
margin:auto;
`
export const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: #f3f4f6;
`

export const Title = styled.div`
color:black;
margin:auto;
font-family:Poppins;
font-size:2.5rem;
Position:absolute;
left:20rem;
top:7rem;
font-weight:600;
`
export const ButtonCSV = styled.button`
width:5rem;
height:2rem;
margin:auto;
display:flex;
justify-content:center;
align-items:center;
position:absolute;
left:22.5%;
top:3%;
`
export const Loader = styled.div`
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid #024179;
  width: 3rem;
  height: 3rem;
  margin-top:1rem;
  font-family:Poppins;
  animation: spin 1s linear infinite;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`
export const Loadervalue = styled.div`
margin-top:1rem;
font-family:Poppins;
font-size:1.5rem;
font-weight:bolder;
color:black;
display:flex;
flex-direction:row;
border: 1px solid black;
padding:1rem;
border-radius:1rem;
`
export const Table = styled.div`
width:50rem;
height:13rem;
margin:auto;
margin-top:5rem;
`
export const TableContainer = styled.div`
display:flex;
flex-direction:row;
`

export const InfoContainer = styled.div`
display:flex;
flex-direction:column;
`

export const Info = styled.div`
width:6rem;
height:2rem;
border:1px solid black;
text-align:center;
line-height:2rem;
box-sizing: border-box;
`

export const StatsContainer = styled.div`
display:flex;
flex-direction:row;
`
export const Stat = styled.div`
width:4rem;
text-align:center;
line-height:2rem;
height:2rem;
border:1px solid black;
box-sizing: border-box;
`

export const AgenceContainer = styled.div`
display:grid;
grid-template-columns:repeat(5, 1fr);
grid-template-rows:repeat(5, 1fr);
grid-column-gap:0px;
grid-row-gap:20px;
`

export const Agencies = styled.div`
display:flex;
flex-direction:row;
width:9.3rem;
height:2rem;
color:white;
background:#232733;
margin:auto;
font-family:Poppins;
cursor:pointer;
justify-content:center;
border-radius:6px;
padding:1rem;
align-items:center;
&:active{background: rgb(0, 196, 243);}
`
export const AgenciesHeader = styled.div`
width:100%;
height:50%;
background-color:#232733;
border-top-left-radius:15px;
border-top-right-radius:15px;
font-family:Poppins;
color:white;
text-align:center;
font-size:1rem;
line-height:5rem;
`
export const TotalStatsContainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
`

export const CircleStats = styled.div`
border: 10px solid #f3f3f3;
border-radius: 50%;
border-top: ${(props) => (props.borderColor ? `10px solid ${props.borderColor}` : '10px solid #024179')};
border-right: ${(props) => (props.borderRightColor ? `10px solid ${props.borderColor}` : '10px solid #024179')};
border-bottom: ${(props) => (props.borderBottomColor ? `10px solid ${props.borderColor}` : '10px solid #024179')};
border-left: ${(props) => (props.borderBottomColor ? `10px solid ${props.borderColor}` : '10px solid #024179')};
width: 5rem;
height: 5rem;
margin-top:1rem;
font-family:Poppins;
`

export const StatsName = styled.div`
margin-top:6.5px;
font-weight:600;
font-family:Poppins;
`
export const StatsValue = styled.div`
position:absolute;
margin-top:3.5rem;
font-family:Poppins;
font-weight:800;
font-size:1.5rem;
`

export const StatsGlobalContainer = styled.div`
background:white;
border-radius:15px;
margin-top:2rem;
padding:1rem;
width:54rem;
position:relative;
left: 13%;
display:flex;
flex-direction:row;
justify-content: space-evenly;
@media(max-width: 1500px) {
  width: 40rem;
}
`
export const StatsBlocName = styled.div`
display:flex;
flex-direction:row;
background:white;
margin-top:2rem;
width:23rem;
height:10rem;
border-radius:15px;
font-size:2rem;
font-family:Poppins;
font-weight:600;
text-align:center;
align-items:center;
justify-content:center;
`

export const PlayerTimeContainer = styled.div`
display:flex;
position: absolute;
left: 45rem;
top: 8.2rem;
flex-direction:column;
background:white;
margin-top:2rem;
width:23rem;
height:9rem;
border-radius:15px;
font-size:1.5rem;
font-family:Poppins;
font-weight:600;
text-align:center;
align-items:center;
justify-content:center;
`

export const AgencyContainer = styled.div`
  position: absolute;
  left: 19rem;
  top: 10.2rem;
  background: white;
  height: 9rem;
  width: 23rem;
  display: flex;
  flex-direction: column;
  border-radius:15px;
  @media(max-width: 1500px) {
    left: 19rem;
  }
`