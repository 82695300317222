import { call, put, takeLatest } from 'redux-saga/effects'
import { CHANGE_PASSWORD } from '../App/constants'
import {
  changePasswordSuccess,
  changePasswordError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'
// const serverPath = `https://ver-client.tgd-test.site`
/**
 * Github repos request/response handler
 */

export function * changePassword ({ credentials }) {
  const changePasswordUrl = `${serverPath}/api/playerTGDs/changePassword?id=${credentials.id}&password=${credentials.password}&access_token=${credentials.token}`
  const getPlayerUrl = `${serverPath}/api/playerTGDs/${credentials.id}?access_token=${credentials.token}`

  const patchStudents = `${serverPathTgdApi}/students?studentId=${credentials.id}`
  const getPlayerTgdApiUrl = `${serverPathTgdApi}/students?studentId=${credentials.id}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${credentials.token}`)
  const body = JSON.stringify({ password: credentials.password, status: 1 })

  const patchOptions = {
    method: 'PATCH',
    headers,
    body
  }

  const getOptions = {
    method: 'GET',
    headers,
  }


  try {
    if (isB2VM) {
      yield call(request, patchStudents, patchOptions)
      const student = yield call(request, getPlayerTgdApiUrl, getOptions)
      console.log(student)
      yield put(changePasswordSuccess(student))
    } else {
      yield call(request, changePasswordUrl, { method: 'POST' })
      const player = yield call(request, getPlayerUrl, { method: 'GET' })
      yield put(changePasswordSuccess(player))
    }
  } catch (err) {
    console.log(err)
    yield put(changePasswordError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * changePasswordData () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(CHANGE_PASSWORD, changePassword)
}
