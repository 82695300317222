import React, { useEffect, useState } from 'react'
import '../../fonts/fonts.css'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import ModalConfirm from '../../components/ModalConfirm/index.js'
import config from '../../config/configurator'
import { Bg, ContentLayout, Loader, FilterGridContainer, DateFilter, GridContainer, StatusContainer, DeleteIcon, ArchiveIcon, ActionDelete, ActionArchive, FirstContainer, ContainerFirstStudent, AvatarContainer, TextFirstCSV} from './styledComponents'
import { AgGridReact } from 'ag-grid-react'
import Avatar4 from '../../images/Avatar4.svg'
import moment from 'moment'
import './AgGridArchive.css'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { Link } from 'react-router-dom'
import UiDelRed from '../../images/closeRed.svg'
import UiArchive from '../../images/Archive.svg'
import { deleteArchiveSaga, getArchivePlayerSaga, revertArchivePlayerSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { deleteArchive, getArchivePlayers, revertArchivePlayer } from '../App/actions'
import ArrowFilter from '../../images/arrow.svg'

const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

const SimuPlayersArchivePage = () => {
  const dispatch = useDispatch()

  const isAdmin = useSelector((state) => state.toJS().global.isAdmin)
  const loading = useSelector((state) => state.toJS().global.loading)
  const archivePlayers= useSelector((state) => state.toJS().global.simuPlayers || {})
  const agencies = useSelector((state) => state.toJS().global.agencies)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const agencyid = useSelector((state) => state.toJS().global.agencyid)

  const [showModal, setShowModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalName, setModalName] = useState('')
  const [confirmText, setConfirmText] = useState('')
  const [playerId, setPlayerId] = useState('')
  const [modalType, setModalType] = useState('')
  const [gridApi, setGridApi] = useState()
  const [dateFilter, setDateFilter] = useState('')


  useEffect(() => {
    dispatch(getArchivePlayers(agencytoken))
  // eslint-disable-next-line
  }, [])

  const findAgencyName = (agencyId) => {
    if (agencies == null) {
      return ''
    }
    if (agencyId == null) {
      return 'Non affilié'
    }
    let agency
    if (isB2VM) {
      agency = agencies.find(a => a.agencyId === agencyId)
    } else {
      agency = agencies.find(a => a.id === agencyId)
    }
    return agency != null ? agency.agencyname || agency.agencyName : 'Aucune agence'
  }

  const confirmDelete = (id, action, firstname) => {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
    setConfirmText('Supprimer')
  }

  const confirmRevertArchive = (id, action, firstname) => {
    setShowModal(true)
    setPlayerId(id)
    setModalText(`${action}`)
    setModalName(`${firstname}`)
    setConfirmText('Activer')
  }

  const deletePlayer = () => {
    dispatch(deleteArchive(agencytoken, playerId, agencyid))
  }

  const revertArchive = () => {
    dispatch(revertArchivePlayer(playerId, agencytoken))
  }

  const getTime = (time) => {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt((time / 60) % 60)

      return h + 'h' + m + 'm'
    }
    return '-----'
  }

  const buildRows = () => {
    const rowData = []
    if (loading) { return null }
    archivePlayers.forEach((player) => {
      rowData.push({
        id: player.studentId,
        eleve: player.firstname + ' ' + player.lastname,
        email: player.email,
        agence: findAgencyName(player.agencyId),
        agencyId: player.agencyId,
        eval: player.evalHours || '-----',
        simulationTotal: getTime(player.playTime),
        actualDrivingTotal: player.licenceData?.actualDriving || '-----',
        licenceDate: moment(player.licenceData?.licenceDate).format('DD/MM/YYYY') || '-----',
        licenceAttempts: player.licenceData?.licenceAttempts || '-----'
      })
    })
    rowData.reverse()
    return rowData
  }

  const filterdate = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue
      if (dateAsString == null) return -1
      var dateParts = dateAsString.split('/')
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0])
      )
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1
      }
    },
    browserDatePicker: true,
  }
  
  const sortableIcons = {
    sortAscending: `<img src=${ArrowFilter} style="height: 16px; width: 12px; margin-left: -26px; transform: rotate(180deg);"/>`,
    sortDescending: `<img src=${ArrowFilter} style="height: 16px; width: 12px; margin-left: -26px;"/>`,
    sortUnSort: `<img src=${ArrowFilter} style="height: 16px; width: 12px; margin-left: -26px; transform: rotate(90deg);"/>`
  }

  const grid = [
    { width: !isAdmin ? 224 : 190, suppressMovable: true, headerName: 'Elèves', field: 'eleve', tooltipField: 'eleve', cellClass: ' rowGreyBackgroundwithImg', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => {
      return config.title === 'ECF' || 'PP ADMIN' ? <Link style={{ paddingLeft: '2.2rem', textOverflow: 'ellipsis', lineHeight: '1.1rem' }} to={{ pathname: config.home + `/edit/${params.data.id}`, query: { id: params.data.id } }}>{params.data.eleve}</Link> : <div style={{ paddingLeft: '2.2rem' }}>{params.data.eleve}</div> } },
    { width: !isAdmin ? 224 : 170, suppressMovable: true, headerName: 'Email', field: 'email', tooltipField: 'email', cellClass: ' rowGreyBackground', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true } },
    { width: !isAdmin ? 98 : 98, suppressMovable: true, headerName: 'Eval', field: 'eval', cellClass: ' rowGreenBackground', suppressMenu: true, unSortIcon: true, sortable: true,icons: sortableIcons, cellRendererFramework: (params) => {
      return (
        !isAdmin && (params.data.eval !== '-----') ? <Link style={{ textDecoration: 'underline' }} to={{ pathname: `/simuplayers/eval/${params.data.id}`, query: { id: params.data.id } }}>{params.data.eval}</Link> : <div>{params.data.eval}</div>
      )
    } },
    { width: !isAdmin ? 138 : 128, suppressMovable: true, headerName: 'Heure de simulateur', field: 'simulationTotal', cellClass: ' rowGreyBackground', suppressMenu: true, unSortIcon: true, sortable: true, icons: sortableIcons },
    { width: !isAdmin ? 179 : 179, suppressMovable: true, headerName: 'Heure de conduite réelle', field: 'actualDrivingTotal', cellClass: ' rowGreyBackground', suppressMenu: true, unSortIcon: true, sortable: true, icons: sortableIcons  },
    { width: !isAdmin ? 137 : 137, suppressMovable: true, headerName: 'Date Permis', field: 'licenceDate', cellClass: ' rowGreyBackground', suppressMenu: true, filter: 'agDateColumnFilter',filterParams: filterdate },
    { width: !isAdmin ? 154 : 144, suppressMovable: true, headerName: 'Présentation examen', field: 'licenceAttempts', cellClass: ' rowGreyBackground', suppressMenu: true, sortable: true, unSortIcon: true, icons: sortableIcons},
  ]

  const agencyGrid = { width: 190, suppressMovable: true, headerName: 'Agence', field: 'agence', cellClass: ' rowGreyBackgroundwithAgencyImg', floatingFilter: true, suppressMenu: true, floatingFilterComponentParams: { suppressFilterButton: true }, cellRendererFramework: (params) => { return config.title === 'ECF' || 'PP ADMIN' ? <Link style={{ paddingLeft:'2.2rem', textOverflow: 'ellipsis', cursor: params.data.agence === 'Aucune agence' ? 'unset' : 'pointer ' }} to={{ pathname: params.data.agence === 'Aucune agence' ? '' : `/agency/edit/${params.data.agencyId}`, query: { id: params.data.agencyId } }}>{params.data.agence}</Link> : <div>{params.data.agence}</div> } }

  const actionGrid = [
    {
      suppressMovable: true,
      width: config.title === ('ECF' && isAdmin) || ('PP ADMIN' && isAdmin) ? 95 : config.title === ('ECF' && !isAdmin) || ('PP ADMIN' && !isAdmin) ? 95 : 95,
      suppressMenu: true,
      field: 'Actions',
      cellClass: 'rowGreyBackground',
      cellRendererFramework: (params) => {
        return (
          config.title === 'ECF' || 'PP ADMIN'
          ?
          <StatusContainer>
            <ActionArchive style={{backgroundColor:'#82FDB5'}} onClick={() => {
              confirmRevertArchive(params.data.id, 'Vous allez réactiver le compte de ', params.data.eleve)
              setModalType('revert')

            }}>
              <ArchiveIcon src={UiArchive} />
            </ActionArchive>
            <ActionDelete style={{border: '0.1px solid red'}} onClick={() => {
              confirmDelete(params.data.id, 'Attention, vous allez supprimer définitivement le compte de ', params.data.eleve)
              setModalType('delete')
            }}>
              <DeleteIcon
                src={UiDelRed}
              />
            </ActionDelete>
          </StatusContainer>
          :
          ''
        )
      }
    }
  ]

  const columns = () => {
    if (isAdmin) {
      const adminGrid = grid
      adminGrid.splice(2,0, agencyGrid)
      return adminGrid.concat(actionGrid)
    }
    if (config.title === 'ECF' || 'PP ADMIN') {
      return grid.concat(actionGrid)
    }
  }

  const gridOptions = {
    defaultColDef: { filter: true, sortable: false },
    columnDefs: columns(),
    enableBrowserTooltips: true
  }
  

  const hasPlayers = archivePlayers.length > 0
  const isLoading = !loading

  useEffect(() => {
    if(gridApi){
    const dateFilterComponent = gridApi.api.getFilterInstance('licenceDate')
    dateFilterComponent.setModel({
      type:'',
      dateFrom: dateFilter
    })
    gridApi.api.onFilterChanged()
  }
  },[dateFilter, gridApi])

  const onGridReady = (params) => {
    setGridApi(params)
    const filters = Array.from(document.getElementsByClassName('ag-input-field-input ag-text-field-input'))
    for (let i = 0; i < filters.length; i++) {
      if (i === 0) {
        filters[i].setAttribute('placeholder', 'Chercher un eleve')
      }
      if (i === 1) {
        filters[i].setAttribute('placeholder', 'Chercher un mail')
      }
      if (i === 2) {
        filters[i].setAttribute('placeholder', 'Chercher une agence')
      }
    }
  }

  if (loading === true) {
    return <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
    <Loader></Loader>
  </div>
  }

  if(archivePlayers == null){
    return <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
    <Loader></Loader>
    </div>
  }

  return (
    <Bg>
      <ModalConfirm
        show={showModal}
        text={modalText}
        name={modalName}
        confirm={() => {modalType === 'delete' ? deletePlayer() : revertArchive(); setShowModal(false)}}
        cancel={() => setShowModal(false)}
        confirmText={confirmText}
      />
        <ContentLayout>
        {hasPlayers && isLoading ?
          <GridContainer>
            <div className={
              isAdmin && config.features.eval ?
                'ag-theme-alpine-Admin-Archive' :
                  isAdmin && !config.features.eval ?
                    'ag-theme-alpine'
                      : 'ag-theme-alpine-Archive'
              } style={{
                  height: 600,
                  width: isAdmin ? 1340 : 1264,
                  position: 'relative'
                }}>
            <FilterGridContainer>
              <DateFilter style={{marginLeft: isAdmin ? '58.3%' : '52.7%'}} type="date" onChange={e => setDateFilter(e.target.value)} />
            </FilterGridContainer>
              <AgGridReact
                rowData={buildRows()}
                gridOptions={gridOptions}
                rowHeight={55}
                onGridReady={onGridReady}
              />
            </div>
          </GridContainer>
          : 
          <FirstContainer>
          <ContainerFirstStudent>
            <AvatarContainer>
              <img alt='avatar 4' src={Avatar4} />
            </AvatarContainer>
            <TextFirstCSV>Il n'y a pas d'élèves clôturés pour le moment</TextFirstCSV>
          </ContainerFirstStudent>
        </FirstContainer>}
          </ContentLayout>
    </Bg>
  )
}

const withConnect = connect(null, null)
const withLoader = injectSaga({ key: 'getArchivePlayers', saga: getArchivePlayerSaga })
const withRevert = injectSaga({ key: 'revertArchivePlayer', saga: revertArchivePlayerSaga })
const withDelete = injectSaga({ key: 'deleteArchive', saga: deleteArchiveSaga })
const arg = compose(withLoader, withConnect, withRevert, withDelete)(SimuPlayersArchivePage)

export default arg
