import styled from 'styled-components'

export const DashboardContainer = styled.div`
  height: 56px;
  background-color: #FFFCD3;
  display: flex;
  position: absolute;
  z-index: 1;
  border-radius: 10px;
  align-items: center;
  justify-content: space-around;
  width: 52%;
`

export const Box = styled.div`
  display: flex;
  align-items: center;
  height: 31px;
`

export const IconFilter = styled.img`
  margin-left: 1.125rem;
  margin-right: 0.75rem;
`
export const FilterText = styled.div`
  color: black;
  font-family: Poppins;
  font-size: 12px;
  line-height: 23.27px;
  display: flex;
  padding-right: 1.25rem;
`