import styled from 'styled-components'
import Select from 'react-select'

export const EntitySelectorContainer = styled.div`
  margin-bottom: 5rem;
  margin-top: 0.5rem;
  width: 100%;
`

export const EntitySelector = styled(Select)`
.react-select__control {
  border-radius: 10px;
  width: 100%;
  color:red;
  font-family:Poppins;
  font-size:13px;
  outline: none;
  border: 1px solid #B3ABBC;
  box-sizing: content-box;
  height: 2.75rem;
  cursor: pointer;
}
.react-select__option {
  font-family: Poppins;
  font-size: 13px;
  cursor:  pointer;
}
.react-select__indicator-separator {
  display: none;
}
.react-select__dropdown-indicator {
  color: black;
}
  & .react-select__control--is-focused {box-shadow: 0px 0px 3px 3px #00C4F3;}
`

export const Bg = styled.div`
  min-height: 100vh;
  height: 100vh;
  width: 100%;
`

export const MainLayout = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  width: 80%;
  background-color: white;
`

export const Container = styled.div`
  width: 100%;
`

export const FormAndLogoContainer = styled.div`,
  width: 30px;
  display: flex;
  margin-top: 4rem;
`

export const FormContainer = styled.div`
  position: relative;
  width: 560px;
  padding-top: 100px;
`

export const UploadContainer = styled.div`
  height: 100%;
  margin-left: 5rem;
`

export const BoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`

export const Name = styled.div`
  font-family: Poppins;
  font-weight: 300;
  font-size: 0.75rem;
  font-style: normal;
  color: #4f4f4f;
  line-height: 18px;
  margin-left: 1rem;
`
export const Input = styled.input`
  border-radius: 10px;
  background-color: white;
  border: 1px solid #b3abbc;
  box-sizing: content-box;
  height: 2.37rem;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  padding-left: 1rem;
  width: calc(100% - 1rem);
  &:focus {
    outline: none;
  }
  &:focus {
    box-shadow: 0px 0px 3px 3px #00c4f3;
  }
  position: relative;
`

export const FeedBack = styled.div`
  color: red;
  margin-left: 1rem;
  font-family: Poppins;
  font-weight: 400;
  font-size: 0.7rem;
  margin-top: -0.5rem;
  position: absolute;
  bottom: 10px;
`

export const DeleteInput = styled.img`
  position: absolute;
  height: 0.6rem;
  widht: 0.6rem;
  &:hover {
    cursor: pointer;
  }
`

export const UploadInput = styled.div`
  border-left: solid 1px black;
  padding: 10px;
  margin-left: 5rem;
  display: flex;
  align-items: center;
`

export const Button = styled.button`
  width: 10.4rem;
  height: 3rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #868383;
  border-radius: 10px;
  background-color: #eff0f6;
  border: unset;
`

export const UploadTitle = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #808080;
  margin-left: 1rem;
  margin-bottom: 0.5rem;
`

export const Success = styled.div`
  position: absolute;
  z-index: 10;
  top: 12rem;
  left: 21rem;
  height: 10rem;
  width: 15rem;
  border-radius: 0.5rem;
  text-align: center;
  background-color: #fffcd3;
  font-family: Poppins;
`
export const TextSuccess = styled.div`
  margin-top: 1.5rem;
  font-size: 1.1rem;
`
export const ImgSuccess = styled.img`
  margin-top: 1rem;
  height: 4rem;
`

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
