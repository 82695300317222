import { call, put, takeLatest } from 'redux-saga/effects'
import { SET_SCALE_EVAL } from '../App/constants'
import {
  setScaleEvalSuccess,
  setScaleEvalError
} from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'

export function * setScaleEval ({ scale, id, token, evalScaleDisplay }) {
   console.log('scaleTGD',scale)
  const url = `${serverPath}/api/agencies/${id}?access_token=${token}`
  const urlTgdApi = `${serverPathTgdApi}/agencies?agencyId=${id}`

  const headers = new window.Headers()
  headers.append('Content-Type', 'application/json')
  headers.append('Authorization', `Bearer ${token}`)

  const body = JSON.stringify({ 
    evalScale: scale,
    evalScaleDisplay: evalScaleDisplay
  })
  
  const options = {
    method: 'PATCH',
    headers,
    body
  }

  try {
    if (isB2VM) {
      yield call(request, urlTgdApi, options)
      yield put(setScaleEvalSuccess(scale, evalScaleDisplay))
    } else {
      yield call(request, url, options)
      yield put(setScaleEvalSuccess(scale, evalScaleDisplay))
    }
  } catch (err) {
    yield put(setScaleEvalError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * setScaleEvalSaga () {
  // Watches for LOAD_REPOS actions and calls getRepos when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SET_SCALE_EVAL, setScaleEval)
}
