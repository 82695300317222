import React from 'react'
import '../../fonts/fonts.css'

// FIXME: React components please...
class Circle extends React.Component {
  componentDidUpdate () {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    const size = this.props.size ? this.props.size : 80
    const canvasSize = this.CanvasSize()
    // const x = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.x) ? this.props.shadow.offset.x : 0.1
    // const y = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.y) ? this.props.shadow.offset.y : 0.05
    const circle = {
      canvas,
      radius: size * 0.5,
      lineWidth: size * this.props.lineWidth || size * 0.15,
      color: 'grey',
      x: canvasSize * 0.5,
      y: canvasSize * 0.5,
      ratio: 1,
      count: this.props.count || 4,
      offset: this.props.offset ? -this.props.offset : -Math.PI * 0.5
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    circle.x = canvasSize * 0.5
    circle.y = canvasSize * 0.5
    circle.color = this.props.color || '#EFF0F6'
    circle.barWidth = this.props.barWidth ? this.props.barWidth : 0.10
    drawCircle(circle)

    const innerCircle = Object.assign({}, circle)

    innerCircle.color = this.props.color || `hsl(${Math.max(0, this.props.ratio - 0.25) * 160},99%,55%)`
    innerCircle.ratio = this.props.ratio ? this.props.ratio : 0.25
    innerCircle.radius -= size * 0.025
    innerCircle.lineWidth -= size * 0.01
    innerCircle.ratio = this.props.ratio
    innerCircle.barWidth = innerCircle.barWidth * 1.5
    drawCircle(innerCircle)
  }

  componentDidMount () {
    const canvas = this.refs.canvas
    const ctx = canvas.getContext('2d')
    const size = this.props.size ? this.props.size : 80
    const canvasSize = this.CanvasSize()
    // const x = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.x) ? this.props.shadow.offset.x : 0.1
    // const y = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.y) ? this.props.shadow.offset.y : 0.05
    const circle = {
      canvas,
      radius: size * 0.5,
      lineWidth: size * this.props.lineWidth || size * 0.15,
      color: 'grey',
      x: canvasSize * 0.5,
      y: canvasSize * 0.5,
      ratio: 1,
      count: this.props.count || 4,
      offset: this.props.offset ? -this.props.offset : -Math.PI * 0.5
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    circle.x = canvasSize * 0.5
    circle.y = canvasSize * 0.5
    circle.color = this.props.color || '#EFF0F6'
    circle.barWidth = this.props.barWidth ? this.props.barWidth : 0.10
    drawCircle(circle)

    const innerCircle = Object.assign({}, circle)

    innerCircle.color = this.props.color || `hsl(${Math.max(0, this.props.ratio - 0.25) * 160},99%,55%)`
    innerCircle.ratio = this.props.ratio ? this.props.ratio : 0.25
    innerCircle.radius -= size * 0.025
    innerCircle.lineWidth -= size * 0.01
    innerCircle.ratio = this.props.ratio
    innerCircle.barWidth = innerCircle.barWidth * 1.5
    drawCircle(innerCircle)
  }

  CanvasSize () {
    let size = this.props.size ? this.props.size : 80
    const x = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.x) ? this.props.shadow.offset.x : 0.1
    const y = (this.props.shadow && this.props.shadow.offset && this.props.shadow.offset.y) ? this.props.shadow.offset.y : 0.05
    size += Math.max(x, y) * size * 2
    return size
  }

  render () {
    const size = this.CanvasSize()
    return (
      <canvas className={this.props.className} ref='canvas' width={size} height={size} />
    )
  }
}

function drawCircle ({ canvas, radius, lineWidth, barWidth, count, offset, ratio, color, x, y }) {
  const ctx = canvas.getContext('2d')
  radius -= lineWidth * 0.2
  ctx.lineWidth = lineWidth
  ctx.strokeStyle = color
  offset += barWidth * (2 * Math.PI / count) / 2
  ctx.beginPath()
  ctx.arc(x, y, radius, offset, offset + 2 * Math.PI * ratio, false)
  ctx.stroke()
}
export default Circle
