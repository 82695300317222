import { withRouter, useLocation, useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import '../../fonts/fonts.css'
import { getSimuPlayersSaga } from '../SimuPlayers/saga'
import SideBar from '../SideBar/index'
import MainTitle from '../../components/MainTitle'
import { connect, useSelector } from 'react-redux'
import { compose } from 'redux'
import injectSaga from '../../utils/injectSaga'
import ProgressBar from '../../components/ProgressBar'
import Circle from '../../components/StatsCircle'
import statsLogo from '../../images/Stats.svg'
import {
  StatsBox,
  Percent,
  BoxTitle,
  Bg,
  Stats,
  StatsRow,
  StatsRowKM,
  SkillTitle,
  SkillsContainer,
  SkillsContainer2,
  MainLayout,
  MainContainer,
  Info,
  InfoContainer,
  StatsTime,
  StatsTitle,
  Score,
  StatsSelector,
  SelectorStatsContainer,
  DashboardContainer
} from './styledComponents'
import axios from 'axios'
import Dashboard from '../../components/Dashboard'
import IOButtonSendData from '../../components/IOButton_SendData'

const StatsSimu = () => {
  const location = useLocation()
  const history = useHistory()

  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const entity = useSelector((state) => state.toJS().global.entity || 'TGD')

  const simuPlayers = useSelector((state) => state.toJS().global.simuPlayers)
  const id = location.query ? location.query.id : location.pathname.split('/')[3]
  const [player, setPlayer] = useState(simuPlayers ? simuPlayers.find((player) => player.id === id || player._id === id) : '')

  const [colors, setColors] = useState([' ', ' ', ' '])
  const [borderBottom, setBorderBottom] = useState([' ', ' ', ' '])
  const [circleSize, setCircleSize] = useState(80)
  const [nbSequence, setNbSequence] = useState(14)

  const getSequenceNumber = async () => {

   const nb = await axios.get(`${process.env.REACT_APP_TGDAPI_SERVER}/sequences/count`, {headers: {Authorization: `Bearer ${agencytoken}`}})
   .catch(e => {
    console.log(e.message)
    setNbSequence(14)
   })
   console.log('data nb', nb.data)
   setNbSequence(nb.data)
  }

  const getStudentById = async () => {
    const student = await axios.get(`${process.env.REACT_APP_TGDAPI_SERVER}/students/${id}`, {headers: {Authorization: `Bearer ${agencytoken}`}})
    .catch(e => {
     console.log(e.message)
    })
    console.log(student.data)
    setPlayer(student.data)
   }

  useEffect(() => {
    getSequenceNumber()
    if (location?.state?.type === 'refresh') {
      getStudentById()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('resize', ResizeCircle)
    // eslint-disable-next-line
  }, [])

  const ResizeCircle = () => {
    setCircleSize(window.innerWidth / 17)
  }


  const getTime = (time) => {
    if (time) {
      const h = parseInt(time / 60 / 60)
      const m = parseInt((time / 60) % 60)

      return h + 'h ' + m + 'm'
    }
    return 'Never played'
  }

  const handleChangeStats = (event) => {
    if (event.value === 'TGD1') {
      history.push(`/simuplayers/stats/${id}`)
    }
  }

  function displayStatsSelector () {
    const statsValues = [
      {value: 'TGD1', label: 'TGD1'},
      {value: 'TGD2', label: 'TGD2'}
    ]
    return (
    <StatsSelector
      classNamePrefix='react-select'
      multiple={false}
      isSearchable={false}
      defaultValue={statsValues[1]}
      onChange={(e) => {handleChangeStats(e)}}
      options={statsValues}>
    </StatsSelector>)
  }

  // switch color in the infoContainer to know where we are
  const onClickColor = (e) => {
    setColors([
      e === 0 ? '#166DD8' : 'black',
      e === 1 ? '#166DD8' : 'black',
      e === 2 ? '#166DD8' : 'black',
    ])
    setBorderBottom([
      e === 0 ? '3px solid #166DD8' : 'black',
      e === 1 ? '3px solid #166DD8' : 'black',
      e === 2 ? '3px solid #166DD8' : 'black',
    ])
  }

  const getRatios = () => {

    const b2vmStats = {
      position: (player.stats && player.stats.position !== -1 && player.stats.position > 1) ? parseFloat(player.stats.position.toFixed(1)) : 0,
      pace: (player.stats && player.stats.pace !== -1 && player.stats.pace > 1) ? parseFloat(player.stats.pace.toFixed(1)) : 0,
      mirror: (player.stats && player.stats.mirror !== -1 && player.stats.mirror > 1) ? parseFloat(player.stats.mirror.toFixed(1)) : 0,
      sight: (player.stats && player.stats.sight !== -1 && player.stats.sight > 1) ? parseFloat(player.stats.sight.toFixed(1)) : 0,
      communication: (player.stats && player.stats.communication !== -1 && player.stats.communication > 1) ? parseFloat(player.stats.communication.toFixed(1)) : 0,
      eco : (player.stats && player.stats.eco !== -1 && player.stats.eco > 1) ? parseFloat(player.stats.eco.toFixed(1)) : 0
    }

      return (
        // here we are going in the state to find the stats and then in the stats we found which stats we need in the json
        <StatsBox>
          <BoxTitle>
            Position
            <Circle size={circleSize} ratio={b2vmStats.position / 100  || 0} />
            <Percent>{b2vmStats.position || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Allure
            <Circle size={circleSize} ratio={b2vmStats.pace / 100  || 0} />
            <Percent>{b2vmStats.pace || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Rétroviseurs
            <Circle size={circleSize} ratio={b2vmStats.mirror / 100  || 0} />
            <Percent>{b2vmStats.mirror || 0}</Percent>
          </BoxTitle>
          {/* <StatsBox2> */}
          <BoxTitle>
            Regard
            <Circle size={circleSize} ratio={b2vmStats.sight / 100  || 0} />
            <Percent>{b2vmStats.sight || 0}</Percent>
          </BoxTitle>
          <BoxTitle>
            Communication
            <Circle size={circleSize} ratio={b2vmStats.communication / 100  || 0} />
            <Percent>
              {b2vmStats.communication || 0}
            </Percent>
          </BoxTitle>
          <BoxTitle>
            EcoConduite
            <Circle size={circleSize} ratio={b2vmStats.eco / 100 || 0} />
            <Percent>{b2vmStats.eco || 0}</Percent>
          </BoxTitle>
          {/* </StatsBox2> */}
        </StatsBox>
      )
  }

  let isB2vm = player.stats || false

    return (
      <Bg>
        <SideBar />
        <MainLayout>
          <MainTitle text='Statistiques' icon={statsLogo} />
          <MainContainer>
            <DashboardContainer>
              <Dashboard height={'48px'} type='infos' data={{firstName: player.firstname, lastName: player.lastname}}/>
              {entity === "IO" ?
                <div style={{position: 'absolute', right: '-60px', bottom: '0px'}}>
                  <IOButtonSendData
                    id={id}
                    accessToken={agencytoken}
                  />
                </div>
              : null}
            </DashboardContainer>
            <InfoContainer>
            <Info
                to={`/simuplayers/edit/${id}`}
                style={{
                  color: colors[2],
                  borderBottom: borderBottom[2],
                }}
                onClick={(e) => onClickColor(2)}
              >
              Informations
            </Info>
            <Stats
                style={{
                  color: colors[0],
                  borderBottom: borderBottom[0],
                }}
                onClick={(e) => onClickColor(0)}
              >
                Statistiques
              </Stats>
              {/* <Parcours
                style={{
                  color: colors[1],
                  borderBottom: borderBottom[1]
                }}
                onClick={(e) => onClickColor(1)}
              >
                Parcours
              </Parcours>
              */}
            </InfoContainer>
            <SelectorStatsContainer>
              {displayStatsSelector()}
            </SelectorStatsContainer>
            <SkillsContainer>
              <SkillTitle>
                <Score>
                  {player.sequenceDoneCountDetail.competence1 && isB2vm ? player.sequenceDoneCountDetail.competence1 : 0}/{nbSequence}
                </Score>
                Compétence 1
                <ProgressBar
                  percentage={
                    player.sequenceDoneCountDetail.competence1 && isB2vm ? 
                    player.sequenceDoneCountDetail.competence1 * 100 / nbSequence : 0
                  }
                />
              </SkillTitle>
            </SkillsContainer>
            <SkillsContainer>
              <SkillTitle>
                <Score>
                  {player.sequenceDoneCountDetail.competence2 && isB2vm ? player.sequenceDoneCountDetail.competence2 : 0}/7
                </Score>
                Compétence 2
                <ProgressBar
                  percentage={
                    player.sequenceDoneCountDetail.competence2 && isB2vm ? 
                    player.sequenceDoneCountDetail.competence2 * 100 / 7 : 0
                  }
                />
              </SkillTitle>
            </SkillsContainer>
            <SkillsContainer>
              <SkillTitle>
                <Score>
                  {player.sequenceDoneCountDetail.competence3 && isB2vm ? player.sequenceDoneCountDetail.competence3 : 0}/6
                </Score>
                Compétence 3
                <ProgressBar
                  percentage={
                    player.sequenceDoneCountDetail.competence3 && isB2vm ? 
                    player.sequenceDoneCountDetail.competence3 * 100 / 6 : 0
                  }
                />
              </SkillTitle>
            </SkillsContainer>
            <StatsRow>
              <StatsTitle>Temps écoulé</StatsTitle>
              <StatsTime>
                {player.playTime && isB2vm ? getTime(player.playTime) : '0h 0m'}
              </StatsTime>
            </StatsRow>

            <StatsRowKM>
              <StatsTitle>Kilomètres parcourus</StatsTitle>
              <StatsTime>
                {player.playDistance && isB2vm
                  ? `${parseInt(player.playDistance / 1000)} km ${
                      parseInt(player.playDistance) % 100
                    }`
                  : '0km'}
              </StatsTime>
            </StatsRowKM>
            <SkillsContainer2>{getRatios()}</SkillsContainer2>
          </MainContainer>
        </MainLayout>
      </Bg>
    )
}

const withLoader = injectSaga({ key: 'SimuPlayersLoader', saga: getSimuPlayersSaga })
const withConnect = connect(null, null)
const arg = compose(withLoader, withConnect)(StatsSimu)

export default withRouter(arg)
