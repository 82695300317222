/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 * NOTE: while this component should technically be a stateless functional
 * component (SFC), hot reloading does not currently support SFCs. If hot
 * reloading is not a necessity for you then you can refactor it and remove
 * the linting exception.
 */

import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { compose } from 'redux'
import { journeysData } from './saga'
import injectSaga from '../../utils/injectSaga'
import { loadJourneys } from '../App/actions'

export const JourneysLoader = (props) => {
  const dispatch = useDispatch()

  const token = useSelector((state) => state.toJS().global.agencytoken)

  useEffect(() => {
    if (token != null) {
      dispatch(loadJourneys(token))
    }
    if (token && props.journeys == null) {
      dispatch(loadJourneys(token))
    }
    // eslint-disable-next-line
  }, [token, props.journeys])

  return <div />
}

const withConnect = connect(null, null)
const withSaga = injectSaga({ key: 'JourneysLoader', saga: journeysData })
const arg = compose(withSaga, withConnect)(JourneysLoader)

export default arg
