
import React from 'react'

const ShouldRefreshAgencies = (props) => {
  if (props.refreshagency === true) {
    return (<div>{props.refreshAgencies(props.token)}</div>)
  }
  return (null)
}

export default ShouldRefreshAgencies
