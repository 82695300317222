
import React, { useEffect, useState } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'
import { compose } from 'redux'
import '../../fonts/fonts.css'
import { agencyLoginData, agencyCheckTokenData } from './saga'
import injectSaga from '../../utils/injectSaga'
import { agencyLogin, agencyCheckToken } from '../App/actions'
import InputPassword from '../../components/InputPassword'
import ElearningLogo from '../../images/elearningLogo.svg'
import MLLogo from '../../imagesMissionLocale/MLLOGO.png'
import LegalMentionImg from '../../images/Legal_mention.svg'
import config from '../../config/configurator'
import { useHistory } from 'react-router'
import SimuLogo from '../../images/simuLogo.png'
import VinciLogo from '../../imagesVinci/LOGO_ASF.svg'
import ECFLogo from '../../imagesECF/Logo.png'
import PPLogo from '../../imagesPP/PPLogo.png'
import { Bg, VerticalContainer, LogoImg, Title, Id, Loader, Mdp,LoginContainer, Input, TextButton, FeedBack, LegalMention, BottomLogoContainer, BottomLogo, LegalMentionText, LoginButton, ButtonsContainer } from './styledComponent'

export function LoginPage () {

  const dispatch = useDispatch()
  const history = useHistory()

  const loginStatus = useSelector((state) => state.toJS().global.loginStatus)
  const error = useSelector((state) => state.toJS().global.error)
  const agencytoken = useSelector((state) => state.toJS().global.agencytoken)
  const oldlocation = useSelector((state) => state.toJS().router.location.pathname)

  const [agencyName, setAgencyName] = useState('')
  const [agencyPassword, setAgencyPassword] = useState('')

  const login = () => {
    dispatch(agencyLogin({ agencyname: agencyName, agencypassword: agencyPassword }))
    history.push(config.features.simu ? '/simuplayers' : 'players')
  }

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      login()
    }
  }

  useEffect(() => {    
    if (agencytoken) {
      dispatch(agencyCheckToken({ token: agencytoken }))
      if (oldlocation !== '/') {
        history.push({ pathname: oldlocation, state: {type: 'refresh'}})  
      } else if (!history.location.pathname.includes('/printereval')){
        history.push({ pathname: config.features.simu ? '/simuplayers' : 'players', state: {type: 'login'}})
      }
    }
    
    // eslint-disable-next-line
  }, [])

  switch (loginStatus) {
    case 'connected':
      return null
    case 'checking token':
      return (
        <>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',textAlign:'center', marginTop:'25%',fontSize:'2rem', flexDirection:'column'}}>
            <Loader></Loader>
          </div>
        </>
      )
    default:
      return (
        <Bg>
          <VerticalContainer>
            {/* <LogoImg src={'../../' + config.imgs.root + '/' + config.layouts.sidebar.imgLogo} /> */}
            <LogoImg src={config.title === 'ECF' ? ECFLogo : config.title === 'ML ADMIN' ? MLLogo : ElearningLogo && config.title === "VINCI" ? VinciLogo : config.title === "PP ADMIN" ? PPLogo : VinciLogo} />
            <Title>Console de gestion</Title>
            <LoginContainer>
              <Id>Identifiant</Id>
              <Input onKeyDown={onKeyPress} value={agencyName} onChange={(e) => setAgencyName(e.target.value)} />
              <Mdp>Mot de passe</Mdp>
              <InputPassword borderColor={error ? 'red' : '#dadada'} onChange={(value) => setAgencyPassword(value)} />
              <ButtonsContainer>
                <LoginButton data-type='premium' onClick={login}>
                  <TextButton>
                    Connexion
                  </TextButton>
                </LoginButton>
              </ButtonsContainer>
            </LoginContainer>
            {error === 'Failed to fetch' ? <FeedBack>Impossible de se connecter au serveur</FeedBack> : (error === '' || error === 'Not Found') ? <FeedBack>Identifiant ou mot de passe incorrect</FeedBack> : null}
            {config.title === 'ML ADMIN'
              ? <BottomLogoContainer>
                <BottomLogo src={SimuLogo} />
                <LegalMentionText>© 2023 The Good Drive. All Rights Reserved.</LegalMentionText>
                </BottomLogoContainer>
              : <LegalMention src={LegalMentionImg} />}
          </VerticalContainer>
        </Bg>
      )
  }
}

const withConnect = connect(null, null)
const withSagaLogin = injectSaga({ key: 'LoginPage', saga: agencyLoginData })
const withSagaTokenCheck = injectSaga({ key: 'TokenCheck', saga: agencyCheckTokenData })
const arg = compose(withSagaTokenCheck, withSagaLogin, withConnect)(LoginPage)

export default arg
