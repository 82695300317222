import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { setScaleEvalSaga } from './saga'
import injectSaga from '../../utils/injectSaga'
import { setScaleEval } from '../App/actions'
import ScaleEvalLayout from '../../components/ScaleEvalLayout'

class ScaleEvalTGD1 extends React.PureComponent {
  render () {
    return <ScaleEvalLayout
    evalScaleDisplay={this.props.evalScaleDisplay}
    agencyId={this.props.agencyId}
    agencyToken={this.props.agencyToken}
    buttons={[{}, { color: 'black', borderBottom: 0 }]}  
    tgdVersion='TGD1'
    scale={this.props.evalScale}// {this.props.evalScale || this.props.evalScale}
    setScale={(scale, evalScaleDisplay) => this.props.setScaleEval(scale, this.props.agencyId, this.props.agencyToken, evalScaleDisplay) }/>
  }
}


const mapStateToProps = (state) => ({
  agencyToken: state.getIn(['global', 'agencytoken']),
  agencyname: state.getIn(['global', 'agencyName']),
  agencyId: state.getIn(['global', 'agencyid']),
  evalScale: state.getIn(['global', 'evalScale']),
  agency: state.getIn(['global', 'agency']),
  evalScaleDisplay: state.getIn(['global', 'evalScaleDisplay']),
  email: state.getIn(['global', 'email'])
})


function mapDispatchToProps (dispatch) {
  return {
    setScaleEval: (scale, id, token, evalScaleDisplay) => {
      dispatch(setScaleEval(scale, id, token, evalScaleDisplay))
    }
  }
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withSaga = injectSaga({ key: 'setScaleEvalSaga', saga: setScaleEvalSaga })
let arg = compose(withSaga, withConnect)(ScaleEvalTGD1)

export default arg
