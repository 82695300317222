import v4 from 'aws-signature-v4'
import config from '../config/configurator'

const awsUrl = asset => {
  if (process.env.REACT_APP_SERVER_MEDIA) {
    return `${process.env.REACT_APP_SERVER_MEDIA}/${asset}`
  } else {
    return v4.createPresignedS3URL(asset, {
      bucket: config.bucket.player.bucket,
      region: config.bucket.player.region,
      key: process.env.REACT_APP_ACCESS_KEY_PLAYER,
      secret: process.env.REACT_APP_SECRET_KEY_PLAYER
    })
  }
}

export default awsUrl
