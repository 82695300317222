import React from 'react'
import styled, { keyframes } from 'styled-components'

const spin = keyframes`
from {
  transform: rotate(0deg);
 }
 to { transform: rotate(360deg);
 }
`

const Loading = styled.div`
  border: 0.4rem solid #f3f3f3;
  border-top: 0.4rem solid #ffca28;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  animation: ${spin} 2s linear infinite;
`

const LoadingSpin = () => {
  return <Loading />
}

export default LoadingSpin
