/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest } from 'redux-saga/effects'
import { GET_AGENCIES } from '../App/constants'
import { getAgenciesSuccess, getAgenciesError } from '../App/actions'

import request from '../../utils/request'

const serverPath = process.env.REACT_APP_SERVER_API
const serverPathTgdApi = process.env.REACT_APP_TGDAPI_SERVER
const isB2VM = process.env.REACT_APP_TYPE === 'b2vm'


export function * getAgencies ({ token }) {
  const urlAgencies = `${serverPath}/api/agencies?access_token=${token}`
  const urlAgenciesTgdApi = `${serverPathTgdApi}/agencies`
  try {
    if (isB2VM) {
      const agenciesTgdApi = yield call(request, urlAgenciesTgdApi, { method: 'GET', headers: {'Authorization': `Bearer ${token}`}})
      yield put(getAgenciesSuccess(agenciesTgdApi))
    } else {
      const agencies = yield call(request, urlAgencies, { method: 'GET' })
      yield put(getAgenciesSuccess(agencies))
    }
  } catch (err) {
    yield put(getAgenciesError(err))
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function * getAgenciesSaga () {
  yield takeLatest(GET_AGENCIES, getAgencies)
}
