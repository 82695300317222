import React from 'react'
import styled, { keyframes } from 'styled-components'

import EditorPopUpWarning from '../../images/editorPopUpWarning.svg'
import {
  SpacerHorizontal,
  YellowButton,
  HollowButton
} from '../../components/StylesEditor/index.js'

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const PopupBg = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.71);
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-name: ${appear};
  animation-duration: 0.3s;
`

const Popup = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color:white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14);
`
const PopupText = styled.div`
  display:flex;
  align-items:center;
  max-width: 37.7rem;
  font-family: Poppins;
  font-size: 0.9rem;
  font-weight: 600;
`

const SendButtonDiv = styled.div`
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  width:17rem;
`

const PopupIcon = styled.img`
 width:1rem;
 height:1rem;
 margin-right:0.3rem;
`

export const PopUp = (props) => {
  return (
    <PopupBg>
      <Popup>
        <PopupText>
          <PopupIcon src={props.icon || EditorPopUpWarning} />
          {props.text}
        </PopupText>
        {props.children}
        <SendButtonDiv>
          {props.textNO && <HollowButton callback={() => props.callbackNo()} text={props.textNO} />}
          {props.textYES && <SpacerHorizontal width='1rem' />}
          {props.textYES && <YellowButton callback={() => props.callbackYes()} text={props.textYES} />}
        </SendButtonDiv>
      </Popup>
    </PopupBg>
  )
}

export default PopUp
