const strize = require('../strize')

module.exports = {
  title: 'MB Admin',
  imgs: {
    root: 'imagesMB'
  },
  home: '/players',
  features: {
    elearning: true,
    simu: false,
    eval: false
  },
  components: {
  },
  bucket: {
    player: {
      bucket: strize('elearningmarqueblanche'),
      region: strize('eu-west-3')
    },
    editor: {
      bucket: strize(''),
      region: strize(''),
      key: strize(''),
      secret: strize('')
    }
  },
  colors: {
    main: '#000000',
    bgSideBar: '#024179',
    ActionMail: '#EFF0F6'
  },
  layouts: {
    sidebar: {
      imgLogo: 'logo_elearning_ecf.svg'
    },
    agencyCreate: {
      logo: true
    }
  }
}
