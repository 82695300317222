import React from 'react'
import '../../fonts/fonts.css'
import SideBar from '../../containers/SideBar/index'
import Button2 from '../../components/Button/Button2'
import ButtonArrowRight from '../../components/Button_ArrowRight'
import MainTitle from '../../components/MainTitle'
import ArrowRight from '../../images/ArrowRight.svg'
import BaremLogo from '../../images/BaremEvalLogo.svg'
import SuccessIcon from '../../images/successface.svg'
import { Input, Bg, MainLayout, ScalesContainer, SelectorScaleEval, SelectorContainer, SelectorScaleEvalContainer, Button, ImgSuccess, TextSuccess, Success, MainContainer, InfoContainer, FilterText, ScalesGridContainer, ScaleColumn, ScalesGridText, ScalesInfoContainer, ScalesVersion, ScalesGridBottomContainer, ScalesRightOptionsContainer, SelectorText } from './styledComponents'


const defaultScale = [
  {
    type: 'B',
    version: 'TGD1',
    values: [50, 50, 45, 40, 35, 35, 33, 30, 25, 20],
  },
  {
    type: 'BEA',
    version: 'TGD1',
    values: [50, 45, 40, 35, 30, 30, 25, 25, 23, 20],
  },
  {
    type: 'AAC',
    version: 'TGD1',
    values: [30, 30, 30, 25, 25, 25, 20, 20, 20, 20]
  },
  {
    type: 'BEA AAC',
    version: 'TGD1',
    values: [30, 30, 30, 25, 25, 25, 20, 20, 20, 20]
  },
  {
    type: 'B',
    version: 'TGD2',
    values: [50, 50, 45, 40, 35, 35, 33, 30, 25, 20],
  },
  {
    type: 'BEA',
    version: 'TGD2',
    values: [50, 45, 40, 35, 30, 30, 25, 25, 23, 20],
  },
  {
    type: 'AAC',
    version: 'TGD2',
    values: [30, 30, 30, 25, 25, 25, 20, 20, 20, 20]
  },
  {
    type: 'BEA AAC',
    version: 'TGD2',
    values: [30, 30, 30, 25, 25, 25, 20, 20, 20, 20]
  }
]

export default class SetScaleEvalLayout extends React.PureComponent {

  constructor(props) {
    super(props)

    console.log('tgd ' + props.tgdVersion)

    const findElement = (type, version) => {
      console.log(type, version)
      return props.scale.find(element => element.type === type && element.version === version)
    }

    let sortedScale = [
      findElement('B', 'TGD1'),
      findElement('BEA', 'TGD1'),
      findElement('AAC', 'TGD1'),
      findElement('BEA AAC', 'TGD1'),
      findElement('B', 'TGD2'),
      findElement('BEA', 'TGD2'),
      findElement('AAC', 'TGD2'),
      findElement('BEA AAC', 'TGD2')
    ]

    let scale = sortedScale.length > 0 && sortedScale[0] ? sortedScale : defaultScale

    if (!scale.find(element => element.version === "TGD1")) {
      scale.filter(element => element.version == null).forEach(element => {
        element.version = 'TGD1'
      })
    }
    if (!scale.find(element => element.version === "TGD2")) {
      defaultScale.filter(element => element.version === "TGD2").forEach(element => {
        scale.push(element)
      })
    }
    if (!scale.find(element => element.type === 'BEA AAC' && element.version === 'TGD1')) {
      scale.push(defaultScale.find(defaultElement => defaultElement.version === 'TGD1' && defaultElement.type === 'BEA AAC'))
    }

    console.log(scale)


    let evalScaleDisplayValue = props.evalScaleDisplay || 'hours'
    this.state = { scale, evalScaleDisplayValue, succes: false, color: [], borderBottom: [] }
    //fill empty blocks?
  }

  evalScaleDisplaySelector() {
    const scaleValues = [
      { value: 'Ne rien afficher', label: 'Ne rien afficher' },
      { value: 'Afficher le nombre d\'heures', label: 'Afficher le nombre d\'heures' },
      { value: 'Afficher le nombre de séquences', label: 'Afficher le nombre de séquences' }
    ]
    let currentValue = this.state?.evalScaleDisplayValue.includes('seq') ? 2 : this.state.evalScaleDisplayValue.includes('hours') ? 1 : 0
    const enableButton = this.state?.evalScaleDisplayValue !== this.props.evalScaleDisplay ? true : false
    console.log('enableButtonValider', enableButton)
    return (
      <SelectorContainer>
        <SelectorText style={{ fontFamily: 'Poppins', fontSize: '1rem' }}>Que voulez-vous afficher dans l'email reçu à la fin d'une evaluation :</SelectorText>
        <SelectorScaleEvalContainer>
          <SelectorScaleEval
            classNamePrefix='react-select'
            multiple={false}
            isSearchable={false}
            placeholder={scaleValues[currentValue].value}
            value={scaleValues.value}
            defaultValue={scaleValues[currentValue].value}
            onChange={(e) => { this.setState({ evalScaleDisplayValue: e.value.includes('heures') ? 'hours' : e.value.includes('séquences') ? 'seq' : 'none' }) }}
            options={scaleValues}>
          </SelectorScaleEval>
          <Button
            disabled={false}
            data-type='premium'
            style={{ backgroundColor: enableButton ? '#00C4F3' : '#EFF0F6', color: enableButton ? 'black' : null, cursor: enableButton ? 'pointer' : 'unset' }}
            onClick={() => this.props.setScale(this.state.scale, this.state.evalScaleDisplayValue)}>Valider</Button>
        </SelectorScaleEvalContainer>
      </SelectorContainer>
    )
  }

  updateSucess() {
    this.setState({ succes: true })
    setTimeout(() => {
      this.setState({ succes: false })
    }, 2000);
  }

  getColumnStyle(index) {
    switch (index) {
      case 0:
        return { backgroundColor: '#CCEAFF' }
      case 1:
        return { backgroundColor: '#B3DFFF' }
      case 2:
        return { backgroundColor: '#99D5FF' }
      case 3:
        return { backgroundColor: '#7DC3F5' }
      default:
        return { backgroundColor: 'white' }
    }
  }

  setNewScale(i, j, e) {
    const newscale = Object.assign([], this.state.scale)
    newscale.filter(value => value.version === this.props.tgdVersion)[i].values[j] = parseInt(e.target.value)
    this.setState({ scale: newscale })
  }

  renderScale() {
    const indexes = new Array(10).fill('0').map((v, i) => i)
    return (
      <MainLayout>
        {
          this.state.succes
            ? <Success>
              <ImgSuccess src={SuccessIcon} />
              <TextSuccess>Le barème a bien été modifié !</TextSuccess>
            </Success>
            : <div />
        }
        <MainTitle text='Barème Eval' icon={BaremLogo} />
        <MainContainer>
          <ScalesContainer>
            <ScalesInfoContainer>
              <ScalesVersion to={{ pathname: `/scaleeval/tgd1` }} style={this.props.buttons[0]} >TGD 1</ScalesVersion>
              <ScalesVersion to={{ pathname: `/scaleeval/tgd2` }} style={this.props.buttons[1]}>TGD 2</ScalesVersion>
            </ScalesInfoContainer>
            <ScalesGridContainer>
              <ScaleColumn style={{ backgroundColor: 'white', width: "8.7rem", alignItems: "flex-end" }}>
                {indexes.map((v, i = 0) => <ScalesGridText style={{ marginTop: "6rem", marginBottom: "-3.61rem", marginRight: "1rem" }} key={('raw-' + i)}>De {i * 10 + 1} à {(i + 1) * 10}<ButtonArrowRight icon={ArrowRight} /></ScalesGridText>)}
              </ScaleColumn>
              {this.state.scale.filter(value => value.version === this.props.tgdVersion).map((v, i = 0) =>
                <ScaleColumn key={v + '-' + i + this.props.tgdVersion} style={this.getColumnStyle(i)}>
                  <p style={{ marginBottom: "2rem", marginTop: "2rem" }}>{this.state.scale.filter(value => value.version === this.props.tgdVersion)[i].type}</p>
                  {indexes.map((v, j = 0) =>
                    <Input key={v + '-' + j}
                      value={this.state.scale.filter(value => value.version === this.props.tgdVersion)[i].values[j]} onChange={(e) => {
                        this.setNewScale(i, j, e)
                      }}
                      style={{}}
                    />
                  )}
                </ScaleColumn>)}
            </ScalesGridContainer>
            <ScalesGridBottomContainer>
              <Button2
                disabled={false}
                text='Confirmer mes barèmes'
                style={{ backgroundColor: '#00C4F3' }}
                onClick={() => { this.props.setScale(this.state.scale); this.updateSucess(); console.log('propsSuccess', this.props.scale) }}
              />
            </ScalesGridBottomContainer>
          </ScalesContainer>
          <ScalesRightOptionsContainer>
            <InfoContainer>
              <FilterText>
                <p>Précisez ci-contre le nombre de séquences prévu selon les scores. Ex : pour le «De 81 à 90», écrire «22» pour 22 heures ou 22 séquences.</p>
              </FilterText>
            </InfoContainer>
            {this.evalScaleDisplaySelector()}
          </ScalesRightOptionsContainer>
        </MainContainer>
      </MainLayout>
    )
  }

  render() {
    if (this.props.player == null) {
      return (
        <Bg>
          <SideBar />
          {this.renderScale()}
        </Bg>
      )
    }
  }
}
